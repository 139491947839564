import React from 'react'
function Logout() {
  
  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center gap-4">
      <h1 className="text-3xl font-bold underline">
        You have succuessfully logged out!
      </h1>
      <button className='bg-light-blue text-black rounded-md w-[200px] py-2 font-semibold'>Login</button>
    </div>
  )
}

export default Logout