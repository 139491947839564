import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthProvider';
import { useParams } from 'react-router-dom';
import { useLeague } from '../contexts/LeagueProvider';

const DraftRoom = () => {
    const { token } = useAuth();
    const { userLeagues } = useLeague();
    const { leagueId } = useParams();
    const [draftablePlayers, setDraftablePlayers] = useState([]);
    const [draftedPlayers, setDraftedPlayers] = useState([]);
    const [error, setError] = useState(null);

    // Find the active league's settings
    const activeLeague = userLeagues.find((league) => league.leagueId === leagueId) || {};
    const pickTime = activeLeague.pickTime || 30; // Default to 30 seconds if undefined
    const rosterSize = activeLeague.rosterSize || 5; // Default roster size if undefined

    const [timer, setTimer] = useState(pickTime); // Set initial timer based on pickTime

    useEffect(() => {
        async function getAllPlayers() {
            try {
                const res = await fetch('/api/vlg/players', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ region: 'na', timespan: 30 }),
                });

                const data = await res.json();

                if (res.status === 200) {
                    setDraftablePlayers(data.data.segments);
                } else {
                    setError('Could not load the players');
                }
            } catch (error) {
                setError('Could not load the players');
            }
        }

        if (!leagueId) return;
        if (!draftablePlayers.length) getAllPlayers();
    }, [leagueId, draftablePlayers.length, token]);

    // Timer to track pickTime for each draft
    useEffect(() => {
        // Only run timer if roster size limit hasn't been reached
        if (draftedPlayers.length < rosterSize && timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(countdown);
        }

        // If timer reaches 0, draft a random player
        if (timer === 0) {
            draftRandomPlayer();
        }
    }, [timer, draftedPlayers.length, rosterSize]);

    const resetTimer = () => {
        setTimer(pickTime); // Reset timer based on pickTime
    };

    const draftRandomPlayer = () => {
        if (draftablePlayers.length > 0) {
            const randomIndex = Math.floor(Math.random() * draftablePlayers.length);
            handleDraftPlayer(draftablePlayers[randomIndex]);
        }
    };

    // Function to handle drafting a player
    const handleDraftPlayer = (player) => {
        if (draftedPlayers.length >= rosterSize) {
            console.log('Roster limit reached');
            return;
        }

        if (!draftedPlayers.find((drafted) => drafted.player === player.player)) {
            setDraftedPlayers([...draftedPlayers, player]);
            setDraftablePlayers(draftablePlayers.filter((p) => p.player !== player.player));
            resetTimer(); // Reset timer after a successful draft
        }
    };

    return (
        <div className="bg-black text-bright-yellow min-h-screen p-4 flex flex-col space-y-4 text-mono">
            {/* Round Timer */}
            <div className="bg-purple p-4 text-center text-2xl font-bold border-2 border-black">
                Time Remaining: {timer} seconds
            </div>

            {/* Draft Order */}
            <div className="flex items-center justify-between bg-purple p-4 border-2 border-black">
                <span className="text-lg font-semibold">Draft Order</span>
                <div className="flex space-x-2">
                    {[...Array(7)].map((_, index) => (
                        <div key={index} className="w-12 h-12 bg-gray-400" />
                    ))}
                </div>
            </div>

            <div className="flex flex-1 overflow-hidden">
                {/* Roster Section */}
                <div className="w-1/4 bg-purple p-4 border-2 border-black">
                    <h2 className="text-lg font-semibold">Roster ({draftedPlayers.length}/{rosterSize})</h2>
                    <ol className="list-decimal ml-4">
                        {draftedPlayers.length > 0 ? (
                            draftedPlayers.map((player, index) => (
                                <li key={index}>{player.player}</li>
                            ))
                        ) : (
                            <p className="text-gray-500">No players drafted yet.</p>
                        )}
                    </ol>
                </div>

                {/* Player List Section */}
                <div className="w-3/4 bg-purple border-solid border border-purple p-4 overflow-y-auto" style={{ maxHeight: '60vh' }}>
                    {draftablePlayers.map((player, i) => (
                        <div key={player.player}>
                            <div className="bg-purple p-4 flex justify-between items-center text-bright-yellow text-mono">
                                <div className="flex gap-4">
                                    <span className="text-5xl font-bold">{i + 1}</span>
                                    <div>
                                        <div className="flex gap-2">
                                            <h2 className="text-2xl font-bold">{player.player}</h2>
                                            <span className="text-yellow-700">{player.org}</span>
                                        </div>
                                        <div className="flex gap-4 text-bright-yellow">
                                            <p>Rating: {player.rating}</p>
                                            <p>Rounds: {player.round_played}</p>
                                            <p>KPR: {player.kills_per_round}</p>
                                            <p>KD: {player.kill_deaths}</p>
                                        </div>
                                    </div>
                                </div>
                                <button 
                                    className="bg-bright-yellow text-purple text-monobold p-4 rounded-md hover:bg-brighter-yellow"
                                    onClick={() => handleDraftPlayer(player)}
                                    disabled={draftedPlayers.length >= rosterSize}
                                >
                                    Draft
                                </button>
                            </div>
                            {i < draftablePlayers.length - 1 && (
                                <div className="border-t-2 border-bright-yellow my-2"></div>
                            )}
                        </div>
                    ))}
                    {error && <p className="text-red-500">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default DraftRoom;
