import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLeague } from '../contexts/LeagueProvider';
import SideNavMenu from './SideNavMenu';


const TeamPageNavBar = ({ leagueId }) => {
  const navigate = useNavigate();
  const { userLeagues } = useLeague();
  const [leagueName, setLeagueName] = useState('');

  useEffect(() => {
    // Find the league based on the passed leagueId prop
    const activeLeague = userLeagues.find((league) => league.leagueId === leagueId);
    if (activeLeague) {
      setLeagueName(activeLeague.leagueName);
    } else {
      setLeagueName('No League Selected');
    }
  }, [leagueId, userLeagues]);

  return (
    <div className="flex justify-between items-center bg-purple p-4">
      <div className="flex gap-4">
        <div className="">
          <SideNavMenu />
        </div>
        <button
        className="bg-bright-yellow text-purple p-2 rounded hover:bg-brighter-yellow"
        onClick={() => navigate('/players')}
      >
          All Players
        </button>
      </div>

      <div className="absolute left-1/2 transform -translate-x-1/2">
        <h1 className="text-2xl text-patua text-bright-yellow font-bold">{leagueName}</h1>
      </div>

      <div className="flex gap-4">
        <button
          className="bg-bright-yellow text-purple p-2 rounded hover:bg-brighter-yellow"
          onClick={() => navigate('/Profile')}
        >
          Profile
        </button>
        <button
          className="bg-bright-yellow text-purple p-2 rounded hover:bg-brighter-yellow"
          onClick={() => navigate('/')}
        >
          Home
        </button>
      </div>
    </div>
  );
};

export default TeamPageNavBar;