// Nav.jsx
import React, { useEffect, useState } from 'react';
import { useLeague } from '../contexts/LeagueProvider';

function Nav({ onLeagueSelect }) {
    const [leagues, setLeagues] = useState([]);  // Store the fetched leagues
    const [toggle, setToggle] = useState(1);     // Handle active section toggle
    const [error, setError] = useState(null);    // Track any potential errors

    // Replace `getLeagues` with `getUserLeagues`
    const { getUserLeagues, userLeagues, loading, userLeaguesError, activeLeagueId, leagueIds } = useLeague();

    useEffect(() => {
        // Fetch leagues from the provider if not already loaded
        const fetchLeagues = async () => {
            try {
                const token = sessionStorage.getItem('token');
                if (token) {
                    await getUserLeagues();  // Trigger fetching leagues from the provider
                }
            } catch (err) {
                setError('Failed to fetch leagues');
            }
        };
        fetchLeagues();
    }, [leagueIds]);

    if (loading) return <div>Loading leagues...</div>;  // Show loading indicator
    if (userLeaguesError) return <div>{userLeaguesError}</div>;  // Show error message if fetch fails

    return (
        <div className="p-4 rounded shadow justify-between items-center">
            <h3 className="text-xl text-protest font-bold mb-4 text-center">Leagues</h3>
            {/* Display a list of leagues */}
            <ul className="px-5 py-4 text-monobold">
                {userLeagues.map((league) => (
                    <li 
                        key={league.leagueId}  // Ensure each item has a unique key
                        className={`bg-bright-yellow text-purple p-2 rounded mb-2 text-center cursor-pointer hover:bg-brighter-yellow 
                            ${league.leagueId === activeLeagueId ? 'border-2 border-white' : ''}`}
                        onClick={() => onLeagueSelect(league.leagueId)}
                    >
                        {league.leagueName}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Nav;
