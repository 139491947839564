import { useState, useEffect } from "react";
import { useAuth } from '../contexts/AuthProvider'; // Make sure this import is necessary
import { Navigate, useNavigate } from "react-router-dom";
import SideNavMenu from "../components/SideNavMenu";
// Other imports as needed

function Profile() {
  const [visibleForm, setVisibleForm] = useState(null);
  const [profileData, setProfileData] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [leagues, setLeagues] = useState([]);
  const { token } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    const email = sessionStorage.getItem("email");

    const fetchProfileAndLeagues = async () => {
      try {
        const profileResponse = await fetch(`/api/user/profile?email=${email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        });
        const profileData = await profileResponse.json();

        if (profileData.success) {
          setProfileData({
            email: profileData.data.email,
            username: profileData.data.username,
            createdAt: new Date(profileData.data.createdAt._seconds * 1000 + profileData.data.createdAt._nanoseconds / 1000000),
          });
        } else {
          setError(profileData.message || 'Failed to fetch user data.');
        }

        const leaguesResponse = await fetch(`/api/user/profile/leagues?email=${email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        });
        const leaguesData = await leaguesResponse.json();

        if (leaguesData.success) {
          setLeagues(leaguesData.leagues);
        } else {
          setError(leaguesData.message || 'Failed to fetch league data.');
        }

      } catch (err) {
        setError('Error fetching data: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileAndLeagues();
  }, [token]);

  const toggleForm = (formName) => {
    setVisibleForm((prev) => (prev === formName ? null : formName));
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="absolute top-4 left-0">
        <SideNavMenu />
      </div>

      <h1 className="text-6xl font-prime mb-4 pt-20 text-brighter-yellow">
        Welcome: <span className="font-formula  text-sky-blue  text-2x">{profileData.username}!</span>
      </h1>
      <h2 className="text-2xl font-prime mb-2 pt-7 text-brighter-yellow">
        Date Joined: <span className="font-formula  text-sky-blue  text-xl">{profileData.createdAt ? profileData.createdAt.toLocaleDateString() : 'N/A'}</span>
      </h2>
      <h2 className="text-2xl font-prime mb-2 pt-7 text-brighter-yellow">
        Email: <span className="font-formula text-sky-blue  text-xl">{profileData.email}</span>
      </h2>

      <h2 className="text-4xl font-prime mb-2 pt-7 text-brighter-yellow">
        Leagues:
      </h2>
      <div className="grid grid-cols-2 gap-x-12 overflow-auto gap-y-12 w-[60vw]">
        {leagues.map((league, index) => (
          <div
            key={index}
            className="bg-bright-yellow hover:bg-brighter-yellow p-4 border-2 font-formula border-black text-purple text-3xl font-bold flex flex-col justify-center items-center rounded-lg"
          >
            <div className="text-3xl ">{league.name}</div>
            <div className="text-2xl">Players: {league.numberOfPlayers}</div>
            <div className="text-2xl">Roster Size: {league.rosterSize}</div>
          </div>
        ))}
      </div>

      <div className="fixed top-0 right-0 m-4 flex space-x-1">
        {!visibleForm && (
          <>
            <button
              type="button"
              className="bg-bright-yellow hover:bg-brighter-yellow font-formula text-black font-bold py-2 px-4 rounded"
              onClick={() => toggleForm('editProfile')}
            >
              {visibleForm === 'editProfile' ? 'Close' : 'Edit Profile'}
            </button>
            <button
              type="button"
              className="bg-bright-yellow hover:bg-brighter-yellow font-formula text-black font-bold py-2 px-4 rounded"
              onClick={() => toggleForm('deleteProfile')}
            >
              {visibleForm === 'deleteProfile' ? 'Close' : 'Delete Account'}
            </button>
            <button
              type="button"
              className="bg-bright-yellow hover:bg-brighter-yellow font-formula text-black font-bold py-2 px-4 rounded"
              onClick={() => navigate('/forgotpassword')}
            >
              Change Password
            </button>
          </>
        )}
      </div>

      {visibleForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          {visibleForm === 'deleteProfile' && <DeleteProfileForm onClose={() => toggleForm('deleteProfile')} />}
          {visibleForm === 'editProfile' && <EditProfileForm onClose={() => toggleForm('editProfile')} />}
        </div>
      )}
    </div>
  );
}


// Define the DeleteProfileForm component
function DeleteProfileForm({ onClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setrePassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useAuth()


  const handleSubmit = async (e) => {
    const correctEmail = sessionStorage.getItem("email");
    e.preventDefault();
    setSuccessMessage('');
    setLoading(true);
    setError(null);

    if (!email || !password || !repassword) {
      setError('All fields are required.');
      setLoading(false);
      return;
    }


    if (password !== repassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    const response = await fetch('/api/user/profile/delete-account', {

      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ email, password, correctEmail }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setSuccessMessage("Profile Successfully deleted");
          setTimeout(() => {
            setSuccessMessage('');
            onClose();
            sessionStorage.clear();
            window.location.href = '/'
          }, 2500);
        } else {
          setError(data.message || 'Failed to delete profile.');
        }
        setLoading(false);
      })
      .catch(err => {
        setError('Error deleting profile: ' + err.message);
        setLoading(false);
      });
  };

  return (
    <div className="bg-gray-600 p-6 rounded-lg shadow-lg w-full max-w-md">
      {successMessage ? (
        <div className="bg-red-600 text-white p-4 rounded mb-4">
          {successMessage}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <h3 className="text-xl font-prime mb-4">Delete Account</h3>

          {error && (
            <div className="bg-red-600 text-white p-4 rounded mb-4">
              {error}
            </div>
          )}

          <label className="block mb-2" htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            placeholder="Enter account email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full mb-4 p-2 border rounded text-black"
            disabled={loading}
          />

          <label className="block mb-2" htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            placeholder="Enter account password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="block w-full mb-4 p-2 border rounded text-black"
            disabled={loading}
          />

          <label className="block mb-2" htmlFor="password">Re-Enter Password</label>
          <input

            id="repassword"
            type="password"
            placeholder="Re-Enter account password"
            value={repassword}
            onChange={(e) => setrePassword(e.target.value)}
            className="block w-full mb-4 p-2 border rounded text-black"
            disabled={loading}
          />

          <button
            type="submit"
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full"
            disabled={loading}
          >
            {loading ? 'Deleting...' : 'Delete Account'}
          </button>

          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded w-full mt-2"
            disabled={loading}
          >
            Cancel
          </button>
        </form>
      )}
    </div>
  );
}

function EditProfileForm({ onClose }) {
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { token } = useAuth()


  const handleSubmit = (e) => {

    if (!email || !userName) {
      setError('All fields are required.');
      //return;
    }
    //const usersEmail = sessionStorage.getItem("email");
    e.preventDefault();
    setSuccessMessage('');
    setLoading(true);
    setError(null);

    const updatedProfile = {
      email: email,
      username: userName,

    };

    fetch(`/api/user/profile/edit-account`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(updatedProfile),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setSuccessMessage("Profile Successfully updated");
          setTimeout(() => {
            setSuccessMessage('');

            onClose();
            window.location.href = '/profile'
          }, 2500);
        } else {
          setError(data.message || 'Failed to update profile.');
        }
        setLoading(false);
      })
      .catch(err => {
        setError('Error updating profile: ' + err.message);
        setLoading(false);
      });
  }

  return (
    <div className="bg-gray-600 p-6 rounded-lg shadow-lg w-full max-w-md">
      {successMessage ? (
        <div className="bg-green-600 text-white p-4 rounded mb-4">
          {successMessage}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <h3 className="text-xl font-prime mb-4">Edit Profile</h3>

          {error && <p className="text-red-500">{error}</p>}

          <label className="block mb-2" htmlFor="userName">
            User Name
          </label>
          <input required
            id="userName"
            type="text"
            placeholder="Enter new user name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="block w-full mb-4 p-2 border rounded text-black"
          />

          <label className="block mb-2" htmlFor="email">
            User Email
          </label>
          <input required
            id="email"
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full mb-4 p-2 border rounded text-black"
          />

          <button
            type="submit"
            className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded w-full"
            disabled={loading}
          >
            {loading ? 'Updating...' : 'Update Profile'}
          </button>

          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded w-full mt-2"
            disabled={loading}
          >
            Cancel
          </button>
        </form>
      )}
    </div>
  );
}


export default Profile;