import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from './components/ProtectedRoute';

// Pages
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Createleague from './pages/Createleague';
import Joinleague from './pages/Joinleague';
import Logout from './pages/Logout';
import Team from './pages/Team';
import ForgotPassword from './pages/ForgotPassword'
import ChangePassword from './pages/ChangePassword'
import LeagueSettings from './pages/LeagueSettings'
import DraftRoom from './pages/DraftRoom';
import DraftRoomTest from './pages/DraftRoomTest';

import SideNavMenu from './components/SideNavMenu';
// Layout

// Styles
import './index.css';

// Contexts
import AuthProvider from './contexts/AuthProvider';
import LeagueProvider from './contexts/LeagueProvider';


function App() {

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/createleague" index element={
              <LeagueProvider>
                <Createleague />
              </LeagueProvider>} />
            <Route path="/league-settings" index element={
              <LeagueProvider>
                <LeagueSettings />
              </LeagueProvider>} />
            <Route path="/dashboard" index element={
              <LeagueProvider >
                <Dashboard />
              </LeagueProvider>} />
            <Route path="/draft-room/:leagueId" index element={
              <LeagueProvider>
                <DraftRoom />
              </LeagueProvider>
            } />
            <Route path="/Team" index element={
              <LeagueProvider >
                <Team/>
              </LeagueProvider>
            } />
            
            <Route path="/joinleague" index element={<Joinleague />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/draft-room-test/:leagueId" element={<DraftRoomTest />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
