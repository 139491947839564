import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';

const ChangePassword = () => {
  const { changePassword, loading } = useAuth();
  const [form, setForm] = useState({
    password: "",
    confirmpassword: "",
  });
  const [errors, setErrors] = useState({
    passwordError: "",
    confirmPasswordError: "",
  });

  const [success, setSuccess] = useState(false)

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('auth') || false)
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     console.log("User is logged in, navigating from login to dashboard")
  //     navigate('/dashboard')
  //   } else {
  //     console.log("User is not logged in")
  //   }
  // }, [isAuthenticated]);


  const validateForm = () => {
    let valid = true;
    console.log("Validating form")
    console.log("Form: ", form)
    if (form.password === "") {
      setErrors(prev => ({ ...prev, passwordError: "Confirm Email is required." }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, passwordError: "" }));
    }

    if (form.confirmpassword === "") {
      setErrors(prev => ({ ...prev, confirmPasswordError: "Confirm Password is required." }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, confirmPasswordError: "" }));
    }

    return valid;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm(prev => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted")
    if (validateForm()) {
      try {
        const email = searchParams.get("email")
        const token = searchParams.get("token")
        await changePassword(form, token, email);
        
        //navigate("/login");
      } catch (err) {
        console.error("Login error:", err);
      }
    }
  };

  return (
    <div className="min-h-screen bg-black flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-w-sm text-center">
        <h1 className="text-2xl font-bold mb-4">Create New Password</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">New Password</label>
            <input
              id="password"
              type="text"
              placeholder="Password"
              onChange={handleChange}
              value={form.password}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="confirmpassword" className="block text-sm font-medium text-gray-700">Confirm New Password</label>
            <input
              id="confirmpassword"
              type="text"
              placeholder="Password"
              onChange={handleChange}
              value={form.confirmpassword}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;