import React, { useState } from 'react'

function DeleteTeam() {
    const [showModal, setShowModal] = useState(false)
    return (
        <>
            <button onClick={() => setShowModal(true)} className="bg-bright-yellow text-purple border-solid border-bright-yellow w-full border-2 py-2 px-4 rounded-md hover:bg-transparent hover:text-bright-yellow duration-300 ease-in">
                Delete Team
            </button>
            {/*This will open the popup content of the button*/}
            {showModal ? (
                <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-purple outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    Are you sure you want to delete this team?
                                </h3>
                            </div>
                            {/*body*/}
                            <div className='relative p-6 flex justify-center'>
                                <h2 className='text-2xl'>League Name</h2>
                            </div>
                            {/*footer*/}
                            <div className='flex auto'>
                                <button onClick={() => setShowModal(false)} className="bg-bright-yellow text-purple border-solid border-bright-yellow w-full border-2 py-2 px-4 rounded-md hover:bg-transparent hover:text-bright-yellow duration-300 ease-in">
                                    Close
                                </button>
                                <button className="bg-bright-yellow text-purple border-solid border-bright-yellow w-full border-2 py-2 px-4 rounded-md hover:bg-transparent hover:text-bright-yellow duration-300 ease-in">
                                    Yes
                                    {/*Add a way for me to delete league by calling the league api */}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='opacity-25  fixed inset-0 z-40 bg-black'></div>
                </>
            ) : null}
        </>
    );
}

export default DeleteTeam