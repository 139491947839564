import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';
import PrimePicksLogo from '../assets/images/PrimePicks.png'

const Login = () => {
  const { loginUser, loading } = useAuth();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    emailError: "",
    passwordError: "",
  });
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('auth') || false)
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      console.log("User is logged in, navigating from login to dashboard")
      navigate('/dashboard')
    } else {
      console.log("User is not logged in")
    }
  }, [isAuthenticated]);


  const validateForm = () => {
    let valid = true;
    console.log("Validating form")
    console.log("Form: ", form)
    if (form.email === "") {
      setErrors(prev => ({ ...prev, emailError: "Email is required." }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, emailError: "" }));
    }

    if (form.password === "") {
      setErrors(prev => ({ ...prev, passwordError: "Password is required." }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, passwordError: "" }));
    }

    return valid;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm(prev => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted")
    if (validateForm()) {
      try {
        await loginUser(form);
        navigate("/dashboard");
      } catch (err) {
        console.error("Login error:", err);
      }
    }
  };

  return (
    <div className="min-h-screen bg-black flex items-center justify-center">
      <div className="bg-purple p-6 rounded-lg shadow-lg w-1/2 max-w-sm text-center">
        <h1 className="text-2xl font-bold mb-4 text-patua text-bright-yellow">Login</h1>
        <p className="text-center mb-6 text-monobold">
          <Link
            to="/register" // Replace with your actual path
            className="text-sky-blue hover:underline"
          >
            New member? Sign up instead
          </Link>
        </p>
        <form onSubmit={handleSubmit} className="space-y-1 text-mono">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-bright-yellow">Email</label>
            <input
              id="email"
              type="email"
              placeholder="Email"
              onChange={handleChange}
              value={form.email}
              className="mt-1 block w-full px-3 py-2 bg-light-blue border border-black rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-bright-yellow">Password</label>
            <input
              id="password"
              type="text"
              placeholder="Password"
              onChange={handleChange}
              value={form.password}
              className="mt-1 mb-7 block w-full px-3 py-2 bg-light-blue border border-black rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <p className="text-center mb-6">
            <Link
              to="/forgotpassword" // Replace with your actual path
              className="text-sky-blue text-monobold hover:underline"
            >
              Forgot Password?
            </Link>
          </p>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-bright-yellow text-purple text-monobold font-semibold rounded-md shadow-sm hover:bg-brighter-yellow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;