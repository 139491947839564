import React, { useEffect, useState } from 'react';
import { useLeague } from '../contexts/LeagueProvider';

const Standings = ({ leagueId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [leagueName, setLeagueName] = useState('');
  const { userLeagues } = useLeague();

  useEffect(() => {
    if (leagueId) {
      // Find the active league whenever leagueId or userLeagues changes
      const activeLeague = userLeagues.find((league) => league.leagueId === leagueId);
      
      if (activeLeague) {
        setLeagueName(activeLeague.leagueName);
        setError(null);
      } else {
        setError('League not found');
      }
    } else {
      setLeagueName('');
      setError('No league selected');
    }
    setLoading(false);
  }, [leagueId, userLeagues]);

  if (loading) {
    return <div>Loading Standings...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="p-4 rounded shadow justify-between items-center text-center">
      <h3 className="text-xl text-protest font-bold mb-4">Standings</h3>
      <h4 className="text-xl font-bold text-mono mb-4">{leagueName}</h4>
    </div>
  );
};

export default Standings;
