import React, { useState, useEffect } from 'react';
import { useLeague } from '../../contexts/LeagueProvider';
import { useAuth } from '../../contexts/AuthProvider.jsx';
import {useNavigate } from 'react-router-dom';
import LeagueSettings from '../../pages/LeagueSettings.jsx';

function InvitePlayerPopup() {
    //This just sets true and false to either show modal or not
    const [showModal, setShowModal] = useState(false)
    
    const { getLeagueSettings, leagueSettings, sendCode,} = useLeague();
    const {doesUserExist} = useAuth();
    const [isSubmitted, setIsSubmitted] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        getLeagueSettings();
      }, []);

    //This is the variable to store data
    const [form, setForm] = useState({
        email: "",
        id: "",
    });

    const [errors, setErrors] = useState({
        emailError: "",
      });

    //This is the input
    const userinputChange =(e) => {
        const { id, value } = e.target;
        setForm(prev => ({ ...prev, [id]: value }));
    };

    const validateForm = async () => {
        let valid = true;
        console.log("Validating form")
        console.log("Form: ", form)
        if (form.email === "") {
          setErrors(prev => ({ ...prev, emailError: "Email is required" }));
          valid = false;
        } else {
          const doesExist = await doesUserExist(form.email);
          if (!doesExist) {
            setErrors(prev => ({ ...prev, emailError: "Account not found" }));
            valid = false;
          } else {
            setErrors(prev => ({ ...prev, emailError: "" }));
          }
        }
        
        return valid;
      };

    const handleSendCode = async (e) => {
        e.preventDefault();
        console.log("Submitted")
        if(await validateForm()) {
            try{
                console.log("waiting for sendCode")
                console.log(form)
                await sendCode(form)
                setIsSubmitted(true)
                console.log("after sendCode")
            }catch(err){
                console.error("Send Code error: ", err)
            }
        }
        setShowModal(false);
    }
    


    return (
        <>
            <button onClick={() => setShowModal(true)} className="bg-bright-yellow text-purple border-solid border-bright-yellow w-full border-2 py-2 px-4 rounded-md hover:bg-transparent hover:text-bright-yellow duration-300 ease-in">
                Invite Player
            </button>
            {/*This will open the popup content of the button*/}
            {showModal ? (
                <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-purple outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    Invite a Player to the League
                                </h3>
                            </div>
                            {/*body
                               This is the user input that will store the input
                            */}
                            <div className='relative p-6 auto'>
                                <form onSubmit={handleSendCode} >
                                <div className='block justify-center item-center p-2'>
                                    <label htmlFor='email'>
                                        Enter the email assosiated with the player's account to send join code.
                                    </label>
                                    <div className='flex justify-center item-center'>
                                    <input 
                                        id="email"
                                        type="email"
                                        placeholder="Enter a Player's Email"
                                        value={form.email}
                                        onChange={(userinputChange)}
                                        className='text-center p-2 w-60 h-10 border rounded bg-purple text-bright-yellow border-bright-yellow'
                                    />
                                    </div>
                                </div>
                                <button
                                 type="submit"
                                 className="bg-bright-yellow text-purple border-solid border-bright-yellow w-full border-2 py-2 px-4 rounded-md hover:bg-transparent hover:text-bright-yellow duration-300 ease-in">
                                    Send Code
                                </button>
                                </form>
                                <p>You typed: {form.email}, {form.id}, {leagueSettings.leagueId}</p>
                            </div>
                            {/*footer
                               This will find the email and send a code when button is clicked
                            */}
                            <div>
                                <button
                                onClick={() => setShowModal(false)} className="bg-bright-yellow text-purple border-solid border-bright-yellow w-full border-2 py-2 px-4 rounded-md hover:bg-transparent hover:text-bright-yellow duration-300 ease-in">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='opacity-25  fixed inset-0 z-40 bg-black'></div>
                </>
            ) : null}
        </>
    );
}

export default InvitePlayerPopup