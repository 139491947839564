import React, { useContext, createContext, useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'; 
import { useAuth } from './AuthProvider'
export const LeagueContext = createContext(null)

function LeagueProvider({children}) {
    const [loading, setLoading] = useState(false)
    const [userLeaguesError, setUserLeaguesError] = useState("")
    const [leagueSettingsError, setLeagueSettingsError] = useState("")
    const [activeLeagueId, setActiveLeagueId] = useState(sessionStorage.getItem("activeLeagueId") || "");
    const [leagueIDs, setLeagueIDs] = useState(sessionStorage.getItem("leagues") ||  [])
    const [leagueSettings, setLeagueSettings] = useState(sessionStorage.getItem(`lid-${activeLeagueId}`) || {})
    const [userLeagues, setUserLeagues] = useState([]);

    const { token } = useAuth();
    const location = useLocation();

    const getUserLeagues = async () => {
      setLoading(true);
      setLeagueSettingsError("");
      try {
        const email = sessionStorage.getItem("email");
        const response = await fetch(`/api/league/leagues/${email}`, {
          method: "GET",
          headers: {
            "Authorization": "Bearer " + token
          }
        });
        if (response.err) {
          setUserLeaguesError("Could not retrieve user leagues. Refresh and try again.");
          return;
        }
        const leagues = await response.json();
        leagues.sort((a, b) => a.leagueName.localeCompare(b.leagueName));
        console.log("LeagueProvider => Leagues: ", leagues);
        setUserLeagues(leagues);
        sessionStorage.setItem("leagues", JSON.stringify(leagues ? leagues.map(league => league.leagueId) : []));
      } catch (err) {
        console.log("Error getting user leagues: ", err);
        setUserLeaguesError("Could not retrieve user leagues. Refresh and try again.");
      } finally {
        setLoading(false);
      }
    };

  const updateLeagueSettings = async (field) => {
    
    console.log("League field: ", field, "Value: ", leagueSettings[field]);
    const res = await fetch(`api/league/${activeLeagueId}`, 
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({fieldName: field, value: leagueSettings[field]})
    })

    const data = await res.json();
    if(data.message === "Success") {
      console.log(data.message);
      const session = sessionStorage.getItem(`lid-${activeLeagueId}`);
      const sessionData = JSON.parse(session);
      sessionData[field] = leagueSettings[field];
      console.log("Session data: ", sessionData);
      sessionStorage.setItem(`lid-${activeLeagueId}`, JSON.stringify(sessionData));
      setLeagueSettings(sessionData);
      setActiveLeagueId(activeLeagueId);

    }
    return data
  }

  const getLeagueSettings = async () => {
    console.log("League ID: ", activeLeagueId);
    var sessionSettings = sessionStorage.getItem(`lid-${activeLeagueId}`);
    console.log('Active League ID: ', activeLeagueId);

    console.log("Session settings: ", sessionSettings);
    if (sessionSettings) {
      setLeagueSettings(JSON.parse(sessionSettings));
      return sessionSettings;
    }

    const response = await fetch(`/api/league/${activeLeagueId}`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + token
      },
    });

      if(response.err) {
        setLeagueSettingsError("Could not retrieve league settings. Refresh and try again.");
        return;
      }
  
      var settings = await response.json();
      // console.log("Settings: ", settings);
      if(settings.err) {
        setLeagueSettingsError("Could not retrieve league settings. Refresh and try again.");
        return;
      }
      setLeagueSettings(settings);

      sessionStorage.setItem(`lid-${activeLeagueId}`, JSON.stringify(settings));
    }
  
    const createleague = async (form) => {
      const league = form;
      league.email = sessionStorage.getItem("email");;
      // console.log("League: ", JSON.stringify(league)) 
      // console.log("Token", token)
      const res = await fetch('/api/league/createleague', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',  // Tell the server that the body is JSON
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(league),
      })
  
      // console.log("Response: ", res)  
  
      if(res.status !== 200) {
        console.log("Failed to create league")
        return { message: "Failed to create league"}
      }
  
      const data = await res.json();
      setActiveLeagueId(data.leagueId);
      setLeagueIDs(prev => [...prev, data.leagueId]);
      // console.log("Data: ", data)
    }

    const deleteLeague = async () =>{
      const res = await fetch(`/api/league/${activeLeagueId}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })

      if(res.status !== 200) {
        console.log("Failed to delete league")
        return;
      }
      // Remove league Id from the list of leagues
      // console.log("Deleting league in league provider ", leagueIDs)

      setLeagueIDs(prev => {
          return prev.filter(id => id !== activeLeagueId)
        }
      );

      // console.log(leagueIDs)
      if(leagueIDs.length > 0) {
        setActiveLeagueId(leagueIDs[0]);
      } else {
        setActiveLeagueId("");  
      }
    }

    const sendCode = async (form) => {
      //fetch information from backend
      console.log("LeagueProvider sendCode")
      setLoading(true)
      console.log(activeLeagueId)
      const send = {
        email: form.email,
        id: activeLeagueId,
      }
    
      console.log(send)

      const res = await fetch('/api/league/sendcode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',  // Tell the server that the body is JSON
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify(send),
      })
  
  
      const data = await res.json();
      console.log("Response: ", data)
      return data;
    }

    useEffect(() => {
      // Check if the current route is the dashboard by comparing location.pathname
      if (token && location.pathname === '/dashboard') 
      {
        getUserLeagues();
      }
      else if (token && location.pathname === '/team') 
        {
          getUserLeagues();
        }
      if(activeLeagueId) {
        sessionStorage.setItem("activeLeagueId", activeLeagueId);
      }
    }, [token, location.pathname, activeLeagueId]);
  
  
    const leagueExports = {
      loading,
      userLeaguesError,
      leagueSettingsError,
      createleague,
      leagueIDs,
      activeLeagueId,
      setActiveLeagueId,
      getLeagueSettings,
      leagueSettings,
      setLeagueSettings,
      setLeagueIDs,
      getUserLeagues,
      userLeagues,
      deleteLeague,
      sendCode,
      updateLeagueSettings,
    }
  
    return (
      <LeagueContext.Provider value={leagueExports}>
          {children}
      </LeagueContext.Provider>
    )
  }
  
  export const useLeague = () => {
      const context = useContext(LeagueContext)
      if(!context) {
          throw new Error('useLeague must be used from /contexts/LeagueProvider.jsx')
      }
      return context
  }
  
  export default LeagueProvider