import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthProvider';
import { useParams } from 'react-router-dom';
import { socket } from '../socket';

const DraftRoomTest = () => {
    const { token, user } = useAuth(); // Get the token from the AuthProvider
    const { leagueId } = useParams();
    const [error, setError] = useState(null);
    const [roomData, setRoomData] = useState(null);
    const [isMyTurn, setIsMyTurn] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const roomId = leagueId;
    useEffect(() => {
      // Join the draft room on component mount
      console.log("Joining room: ", leagueId, user);
      socket.emit('joinRoom', { roomId: leagueId, username: user});

      // Listen for updates to the room state
      socket.on('roomUpdate', (data) => {
        setRoomData(data);

        // Check if it's the user's turn
        const currentUser = data.users[data.currentTurn];
        setIsMyTurn(currentUser && currentUser.id === socket.id);
      });

      // Listen for 'youTurn' event from the backend
      socket.on('yourTurn', () => {
        setIsMyTurn(true);
      });

      // Clean up on component unmount
      return () => {
        socket.off('roomUpdate');
        socket.off('yourTurn');
      };
    }, [roomId, user]);

    const handleSelectPlayer = (player) => {
      if(isMyTurn) {
        socket.emit('selectPlayer', {roomId, player });
        setSelectedPlayer(player);
        setIsMyTurn(false);
      }
    }

    if(!roomData) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <h2>Draft Room: {roomId}</h2>
        <h3>Players available for drafting:</h3>
        <ul>
          {roomData.availablePlayers.map((player) => (
            <li key={player}>
              {player}
              {isMyTurn && (
                <button onClick={() => handleSelectPlayer(player)}>
                  Draft
                </button>
              )}
            </li>
          ))}
        </ul>
  
        <h3>Draft Order:</h3>
        <ul>
          {roomData.users.map((user, index) => (
            <li key={user.id} style={{ fontWeight: index === roomData.currentTurn ? 'bold' : 'normal' }}>
              {user.username} {index === roomData.currentTurn && ' (Picking)'}
            </li>
          ))}
        </ul>
  
        <h3>Your Roster:</h3>
        <ul>
          {(roomData.rosters[socket.id] || []).map((player) => (
            <li key={player}>{player}</li>
          ))}
        </ul>
      </div>
    );
    // useEffect(() => {
    //     async function getAllPlayers() {
    //         const res = await fetch('/api/vlg/players', {
    //             method: 'POST',
    //             headers: {
    //               'Content-Type': 'application/json',
    //               'Authorization': "Bearer " + token,
    //             },
    //             body: JSON.stringify({ region: "na", timespan: 30 })
    //         })

    //         const data = await res.json();

    //         if (res.status === 200) {
    //             console.log(data.data.segments[0])
    //             setDraftablePlayers(data.data.segments);
    //         }

    //         setError("Could not load the players");
    //     }

    //     if(!leagueId) {
    //         return;
    //     }

    //     if(!draftablePlayers.length) {
    //         console.log("Getting all players"); 
    //         getAllPlayers();
    //     }
    // }, []);

  // return (
  //   <div className='bg-black text-bright-yellow min-h-screen'>
  //     <div className='flex flex-col items-center justify-center gap-4 px-[10%]'>
  //       {draftablePlayers.map((player, i) => (
  //         <div key={player.player} className='bg-purple bg-opacity-50 border-solid border border-purple w-full p-4 flex justify-between font-mono'>
  //           <div className='flex gap-4'>
  //             <span className='text-5xl font-patua'>{i + 1}</span>
  //             <div>
  //               <div className='flex gap-2'>
  //                 <h2 className='text-2xl font-formula'>{player.player}</h2>
  //                 <span className='text-yellow-700 font-monobold'>{player.org}</span>
  //               </div>
  //               <div className='flex gap-4 text-sky-blue'>
  //                 <p>Rating: {player.rating}</p>
  //                 <p>Rounds: {player.round_played}</p>
  //                 <p>KPR: {player.kills_per_round}</p>
  //                 <p>KD: {player.kill_deaths}</p>
  //               </div>
  //             </div>
              
  //           </div>
  //           <button className='bg-bright-yellow bg-opacity-75 p-4 rounded-md'>Draft</button>
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // );
};

export default DraftRoomTest;
