import React from 'react';
import Roster from './Roster';

const TeamDetails = () => {
  return (
    <div className="space-y-8">
      {/* Team Info Section */}
      <div className="p-4 bg-purple rounded shadow text-bright-yellow">
        <h2 className="text-xl text-protest font-bold mb-2 flex justify-center">User's Team Name</h2>
  
        <div className="flex justify-between">
          <h3 className="text-lg text-protest font-semibold">Team Points</h3>
          <h3 className="text-lg text-protest font-semibold">Team Stats</h3>
        </div>
      </div>


      {/* Player Stats Section */}
      <div className="p-4 bg-purple text-bright-yellow rounded shadow">
        <h3 className="text-xl text-protest font-bold mb-2">Your Players</h3>
        <Roster type="active" />

        <h3 className="text-xl text-protest font-bold mt-8 mb-2">Benched Players</h3>
        <Roster type="benched" />
      </div>
    </div>
  );
};

export default TeamDetails;
