import React from 'react';

const Roster = ({ type }) => {
  return (
    <table className="min-w-full text-mono table-auto border-collapse border border-gray-300">
      <thead>
        <tr>
          <th className="border border-gray-300 p-2">Player Name</th>
          <th className="border border-gray-300 p-2">Player Team</th>
          <th className="border border-gray-300 p-2">Player Stats</th>
        </tr>
      </thead>
      <tbody>
        {/* Example rows */}
        <tr>
          <td className="border border-gray-300 p-2">Player 1</td>
          <td className="border border-gray-300 p-2">Team 1</td>
          <td className="border border-gray-300 p-2">Stats 1</td>
        </tr>
        <tr>
          <td className="border border-gray-300 p-2">Player 2</td>
          <td className="border border-gray-300 p-2">Team 2</td>
          <td className="border border-gray-300 p-2">Stats 2</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Roster;
