import React, { useContext, createContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams  } from 'react-router-dom';

export const AuthContext = createContext(null)

function AuthProvider({ children }) {
  const [user, setUser] = useState(sessionStorage.getItem("email") || null)
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('auth') || false)
  const [token, setToken] = useState(sessionStorage.getItem('token') || null)
  const [role, setRole] = useState(sessionStorage.getItem('role') || null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const navigate = useNavigate();

  async function registerUser(e) {
    setLoading(true)
    console.log(e)
    if (e.password.value !== e.confirmPassword.value) {
      console.log("Passwords do not match")
      return;
    }

    const res = await fetch("/api/auth/register", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',  // Tell the server that the body is JSON
      },
      body: JSON.stringify(e)
    })

    const data = await res.json()
    console.log("Data: ", data)


    if (res.status !== 201) {
      console.log("Failed to register user")
      return
    }
    if (!data) {
      setError("Failed to register user")
    }

    sessionStorage.setItem('auth', true)
    sessionStorage.setItem('token', data.token)
    sessionStorage.setItem('username', data.username)
    sessionStorage.setItem('email', data.email)
    setIsAuthenticated(data.auth)
    setToken(data.token)
    setUser(data.email)
    setLoading(false)
  }

  const loginUser = async (form) => {
    const user = form;
    console.log("user: ", JSON.stringify(user))

    const res = await fetch('/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',  // Tell the server that the body is JSON
      },
      body: JSON.stringify({ user: user }),
    })

    console.log("Response: ", res)

    if (res.status !== 200) {
      console.log("Failed to login user")
      return { message: "Failed to login user" }
    }

    const data = await res.json();
    console.log("Data: ", data)

    sessionStorage.setItem('auth', data.auth)
    sessionStorage.setItem('token', data.token)
    sessionStorage.setItem('email', data.email)
    sessionStorage.setItem('username', data.username)
    sessionStorage.setItem('leagues', data.leagues)
    setIsAuthenticated(data.auth)
    setToken(data.token)
    setUser(data.email)
  }

  const logoutUser = async () => {
    setLoading(true)
    const res = await fetch('/api/auth/logout', {
      method: 'POST',
      headers: {
        'Authorization': "Bearer " + token
      },
      body: JSON.stringify({user: user})
    })

    if (res.status !== 200) {
      console.log("Failed to logout user")
      return
    }

    const data = await res.json();

    console.log("Data: ", data)

    sessionStorage.clear()
    setToken(data.token)
    setRole(data.username)
    setUser(data.email)
    setIsAuthenticated(false)
    setLoading(false)
    console.log(JSON.stringify({ user: user }))
    navigate('/login')
  }

  const resetPassword = async (form) => {
    setLoading(true)
    console.log(JSON.stringify(form))

    const res = await fetch('/api/auth/resetpassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',  // Tell the server that the body is JSON
        'Authorization': "Bearer " + token
      },
      body: JSON.stringify(form)
    })

    console.log("Response: ", res)

    if (res.status !== 201) {
      console.log("Failed to reset password")
      return { message: "Failed to reset password" }
    }

    const data = await res.json();
    console.log("Data: ", data)
    setLoading(false)
  }

  const changePassword = async (form, token, email) => {
    setLoading(true)
    console.log(JSON.stringify(form))


    const body = {
      newPassword: form.password,
      token: token,
      email: email,
    }
    
    const res = await fetch('/api/auth/changepassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',  // Tell the server that the body is JSON
        'Authorization': "Bearer " + token
      },
      body: JSON.stringify(body)
    })

    console.log("Response: ", res)

    if (res.status !== 200) {
      console.log("Failed to change password")
      return { message: "Failed to change password" }
    }

    const data = await res.json();
    console.log("Data: ", data)
  }

  const doesUserExist = async (email) => {
    setLoading(true)

    const body = {
      email: email,
    }

    const res = await fetch('/api/auth/doesuserexist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',  // Tell the server that the body is JSON
        'Authorization': "Bearer " + token
      },
      body: JSON.stringify(body)
    })

    const data = await res.json();
    console.log("Response: ", data.exist)
    return data.exist
  }

  useEffect(() => {
  }, [isAuthenticated, token, role, loading, error]);


  const authExports = {
    user,
    isAuthenticated,
    token,
    role,
    loading,
    error,
    registerUser,
    loginUser,
    logoutUser,
    resetPassword,
    changePassword,
    doesUserExist,
  }

  return (
    <AuthContext.Provider value={authExports}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used from /contexts/AuthProvider.jsx')
  }
  return context
}

export default AuthProvider