import React from 'react'
import { useAuth } from '../contexts/AuthProvider'
import { useNavigate } from 'react-router-dom'
import {Link} from 'react-router-dom'
import Profile from "../images/address-card-icon-96x75-8jt7spdg.png"
import Create from "../images/teamspeak-icon-96x96-njoyu22k.png"
import Join from "../images/user-friends-icon-96x68-7ucc3csj.png"
import Team from "../images/users-icon-96x68-b5xhnfe2.png"
import PrimePicksLogo from '../assets/images/PrimePicks.png'

import SideNavMenu from '../components/SideNavMenu'

function Home() {
  const { logoutUser, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const login = () => {
    navigate('/login')

  }

  const register = () => {
    navigate('/register')
  }

  const logout = async () => {
    await logoutUser();
    navigate("/login")
  }

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center gap-2">
      <nav>
        {!isAuthenticated ? (
           <div className="absolute top-0 right-0">
           <button onClick={login} className='bg-bright-yellow hover:bg-brighter-yellow duration-200 ease-in text-purple px-12 py-1 border-2 border-purple rounded-l-md text-xl top-0 right-0'>Login</button>
           <button onClick={register} className='bg-bright-yellow hover:bg-brighter-yellow duration-200 ease-in text-purple px-12 py-1 border-2 border-purple text-xl'>Sign-up</button>
         </div>
        ) : (
          <div className="absolute top-0 right-0">
           <button onClick={logout} className='bg-bright-yellow hover:bg-brighter-yellow duration-200 ease-in text-purple px-12 py-1 border-2 border-purple rounded-l-md text-xl top-0 right-0'>Logout</button>
         </div>
        )}
      </nav>
      
      <div className="absolute top-0 left-0">
        <SideNavMenu/>
      </div>
      <div className='flex items-center justify-center'>
        <img src={PrimePicksLogo} alt="" className='object-fit size-2/4' />
      </div>

      <div>
        <div className='flex items-center justify-center'>
          <Link to='/createleague' className='text-center text-xl py-2 w-60 h-15 border-2 rounded-tl-full text-purple border-purple bg-bright-yellow hover:bg-brighter-yellow duration-200 ease-in'><img src={Create} className='w-6 h-6 inline-block'/> Create League</Link>
          <Link to='/joinleague' className='text-center text-xl py-2 w-60 h-15 border-2 rounded-tr-full text-purple border-purple bg-bright-yellow hover:bg-brighter-yellow duration-200 ease-in'>Join League <img src={Join} className='w-6 h-6 inline-block'/></Link>
        </div>
        
        <div className='flex items-center justify-center'>
          <Link to='/team' className='text-center text-xl py-2 w-60 h-15 border-2 rounded-bl-full text-purple border-purple bg-bright-yellow hover:bg-brighter-yellow duration-200 ease-in pl-2 relative'><img src={Team} className='w-6 h-6 inline-block'/> Teams</Link>
          <Link to='/profile' className='text-center text-xl py-2 w-60 h-15 border-2 rounded-br-full text-purple border-purple bg-bright-yellow hover:bg-brighter-yellow duration-200 ease-in'>Profile <img src={Profile} className='w-6 h-6 inline-block'/></Link>
        </div>
      </div>
    </div>
  )
}

export default Home
