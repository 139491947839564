import React from 'react'
import { useState } from 'react'
import { useAuth } from '../contexts/AuthProvider'
import { Link, useNavigate } from 'react-router-dom';

export default function Register() {
  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const { registerUser, loading } = useAuth();
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    username: "",
  });
  const [errors, setErrors] = useState({
		emailError: "",
		passwordError: "",
    confirmPasswordError: "",
    usernameError: "",
	});


  const navigate = useNavigate();

  const validateForm = () => {
    let valid = true;
    console.log("Validating form")
    console.log("Form: ", form)
    if (form.email === "") {
      setErrors(prev => ({ ...prev, emailError: "Email is required" }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, emailError: "" }));
    }

    if (form.password === "") {
      setErrors(prev => ({ ...prev, passwordError: "Password is required" }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, passwordError: "" }));
    }

    if (form.confirmPassword === "") {
      setErrors(prev => ({ ...prev, confirmPasswordError: "Confrim password is required" }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, confirmPasswordError: "" }));
    }

    
    if (form.username === "") {
      setErrors(prev => ({ ...prev, usernameError: "Username is required" }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, usernameError: "" }));
    }

    return valid;
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm(prev => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    console.log(e.target)
    e.preventDefault();
    console.log("Submitted")
    if (validateForm()) {
      try {
        await registerUser(form);
        navigate('/dashboard');
        console.log("Submitted")
      } catch (err) {
        console.error("Sign up error:", err);
      }
    };
  }

  return (
    <div className="min-h-screen bg-black flex items-center justify-center">
      <div className="bg-purple p-6 rounded-lg shadow-lg w-1/2 max-w-sm text-center">
        <h1 className="text-2xl text-bright-yellow font-bold mb-4">Create an Account</h1>
        <p className="text-center mb-6">
          <Link
            to="/login" // Replace with your actual path
            className="text-sky-blue hover:underline"
          >
            Already have one? Log in instead
          </Link>
        </p>
        <div className='text-red-500'>
          {errors.emailError != "" && <p>{errors.emailError}</p>}
          {errors.passwordError != "" && <p>{errors.emailError}</p>}
          {errors.confirmPasswordError != "" && <p>{errors.confirmPasswordError}</p>}
          {errors.usernameError != "" && <p>{errors.usernameError}</p>}
        </div>
        <form onSubmit={(e) => handleSubmit(e)} className="space-y-2 mt-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-bright-yellow">Email</label>
            <input
              id="email"
              type="email"
              placeholder="Email"
              onChange={(e) => handleChange(e)}
              value={form.email}
              className="mt-1 block w-full px-3 py-2 bg-light-blue border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-bright-yellow">Username</label>
            <input
              id="username"
              type="text"
              placeholder="Username"
              onChange={(e) => handleChange(e)}
              value={form.username}
              className="mt-1 block w-full px-3 py-2 bg-light-blue border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-bright-yellow">Password</label>
            <input
              id="password"
              type="text"
              placeholder="Password"
              onChange={(e) => handleChange(e)}
              value={form.password}
              className="mt-1 block w-full px-3 py-2 bg-light-blue border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-bright-yellow">Confirm Password</label>
            <input
              id="confirmPassword"
              type="text"
              placeholder="Confirm Password"
              onChange={(e) => handleChange(e)}
              value={form.confirmPassword}
              className="mt-1 block w-full px-3 py-2 bg-light-blue border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-bright-yellow text-purple font-semibold rounded-md shadow-sm hover:bg-brighter-yellow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Sign Up
          </button>
        </form>
      </div>
    </div>
  )
}