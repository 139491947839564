import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthProvider"; 
import { useNavigate } from "react-router-dom";

function Joinleague() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useAuth();
  const [leagues, setLeagues] = useState([]);
  const navigate = useNavigate();
  const [visibleForm, setVisibleForm] = useState(null);

  useEffect(() => {
    const email = sessionStorage.getItem("email");
    setLoading(true);

    fetch(`/api/join/JoinLeague/get-leagues?email=${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setLeagues(data.leagues);
          console.log("This is your League Data: ", data.leagues);
        } else {
          setError(data.message || 'Failed to fetch user data.');
          console.log("This is your League Data: No Data");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError('Error fetching data: ' + err.message);
        setLoading(false);
      });
  }, [token]);

  const toggleForm = (formName) => {
    setVisibleForm((prev) => (prev === formName ? null : formName));
  };

  return (
    <div className="min-h-screen bg-purple flex flex-col items-center justify-center text-purple">
      <div className="flex items-center justify-between fixed top-4 w-full px-10">
        <button
          type="button"
          className="bg-bright-yellow hover:bg-brighter-yellow text-2xl font-bold py-4 px-6 rounded"
          onClick={() => toggleForm("joinLeague")}
        >
          Join League
        </button>

        <div className="bg-bright-yellow py-4 px-10 rounded">
          <h1 className="text-5xl font-formula">Leagues</h1>
        </div>

        <button
          type="button"
          className="bg-bright-yellow hover:bg-brighter-yellow font-bold text-2xl py-4 px-8 rounded"
          onClick={() => navigate("/Profile")}
        >
          Profile
        </button>
      </div>

      <div className="flex items-center justify-center w-full h-full pt-20">
  <div className="bg-black w-[80vw] h-[80vh] rounded p-10 flex flex-col justify-start items-center"> {/* Change justify-center to justify-start */}
    {loading ? (
      <div>Loading leagues...</div>
    ) : leagues.length > 0 ? (
      <div className="grid grid-cols-2 gap-x-12 overflow-auto gap-y-12 w-[60vw]">
        {leagues.map((league, index) => (
          <div
            key={index}
            className="bg-bright-yellow hover:bg-brighter-yellow p-4 border-2 font-formula border-black text-purple text-3xl font-bold flex flex-col justify-center items-center rounded-lg" // Change items-center to items-start
          >
            
            <div className="text-3xl ">{league.name}</div> 
            <div className="text-2xl">Players: {league.numberOfPlayers}</div> 
            <div className="text-2xl">Roster Size: {league.rosterSize}</div> 

          </div>
        ))}
      </div>
    ) : (
      <div className="flex items-center flex-col w-full h-full pt-20">
        <h2 className="text-bright-yellow text-4xl font-bold">
          You are currently not in any leagues
        </h2>
        <h2 className="text-bright-yellow text-4xl font-bold">
          Join or Create a league
        </h2>

        <div className="grid grid-cols-2 gap-x-12 gap-y-12 pt-4">
          <button
            type="button"
            className="bg-bright-yellow hover:bg-brighter-yellow font-bold text-2xl py-4 px-8 rounded"
            onClick={() => toggleForm("joinLeague")}
          >
            {visibleForm === "joinLeague" ? "Close" : "Join League"}
          </button>

          <button
            type="button"
            className="bg-bright-yellow hover:bg-brighter-yellow font-bold text-2xl py-4 px-8 rounded"
            onClick={() => navigate("/CreateLeague")}
          >
            Create League
          </button>
        </div>
      </div>
    )}

    {visibleForm === "joinLeague" && <JoinALeague onClose={() => toggleForm(null)} />}
  </div>
</div>
    </div>
  );
}

function JoinALeague({ onClose  }) {
  const [leagueID, setLeagueID] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { token } = useAuth();
 // const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage('');


    try {
      const userEmail = sessionStorage.getItem('email'); 
      const targetLeague = leagueID;

      if(leagueID.length!=6 )
      {
        setError('League code consist of 6 characters' );
        setLoading(false);  // Stop loading
        return; 
      }

      if(!/^[a-zA-Z0-9]+$/.test(leagueID))
      {
        setError('Leauge code consists of alphanumeric characters' );
        setLoading(false);  // Stop loading
        return; 
      }
      const response = await fetch(`/api/join/joinLeague/add-league`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token, 
        },
        body: JSON.stringify({
          email: userEmail, 
          targetID: targetLeague, 
        }),
      });

      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Successfully joined the league!');
        setLeagueID(''); 
        //onLeagueUpdate(); 
        setTimeout(() => 
          {
          setSuccessMessage('');
          window.location.href='/JoinLeague';
          //navigate("/Joinleague");
        }, 2000 );
      } else {
        setError(data.message || 'Failed to join the league.');
      }
    } catch (err) {
      setError('Error joining the league: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-purple p-6 text-bright-yellow rounded-lg shadow-lg w-full max-w-md">
        {successMessage ? (
          <div className="bg-green-600 p-4 rounded mb-4">{successMessage}</div>
        ) : (
          <>
            <h3 className="text-xl font-prime flex justify-center mb-4">Join League</h3>

            {error && <p className="text-red-500 flex justify-center">{error}</p>}

            <form onSubmit={handleSubmit}>
              <label className="mb-2 flex justify-center" htmlFor="leagueID">
                Enter The League's Unique ID
              </label>
              <input
                required
                id="leagueID"
                type="text"
                placeholder="Enter 5 digit League ID"
                value={leagueID}
                onChange={(e) => setLeagueID(e.target.value)}
                className="block w-full mb-4 p-2 border rounded text-black"
              />

              <button
                type="submit"
                className="bg-bright-yellow hover:bg-brighter-yellow text-purple font-bold py-2 px-4 rounded w-full"
                disabled={loading}
              >
                {loading ? 'Joining...' : 'Join League'}
              </button>
            </form>

            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 hover:bg-gray-700 text-purple font-bold py-2 px-4 rounded w-full mt-2"
              disabled={loading}
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
}



export default Joinleague;
