import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLeague } from '../contexts/LeagueProvider';
import TrophyLogo from '../assets/images/PrimeTrophy.png'

export default function Createleague() {
  const { createleague, loading } = useLeague();
  const [form, setForm] = useState({
    leagueName: "",
    draftDate: "",
    pickTime: "",
    rosterSize: "",
    leagueSize: "",
    tournamentName: "",
    killScore: "",
    deathScore: "",
    assistScore: "",
    headshotScore: "",
  });
  const [errors, setErrors] = useState({
    leagueNameError: "",
    draftDateError: "",
    pickTimeError: "",
    rosterSizeError: "",
    leagueSizeError: "",
    scoreError: "",
  });

  const [tournamentNames, setTournamentNames] = useState([])

  const navigate = useNavigate();



  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const token = sessionStorage.getItem('token');
        let data = await fetch(`/api/vlg/tournament/`, {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + token
          }
        });
        data = await data.json()
        if (data) {
          console.log(data.Tournaments)
          setTournamentNames(data.Tournaments);  // Set the Tournaments in state
        } else {
          console.log("Data is null")
        }
      } catch (err) {
        console.log('Failed to fetch leagues');
        console.log(err)
      }
    };
    fetchTournaments();  // Trigger the fetch on component mount
  }, []);

  const handleSubmit = async (e) => {
    console.log(e)
    e.preventDefault();
    console.log("Submitted")
    if (validateForm()) {
      try {
        let email = sessionStorage.getItem("email")
        await createleague(form, email);
        navigate('/dashboard');
        console.log("Submitted")
      } catch (err) {
        console.error("Error creating league:", err);
      }
    };
  }

  const validateForm = () => {
    let valid = true;
    console.log("Validating form")
    console.log("Form: ", form)

    if (form.leagueName === "") {
      setErrors(prev => ({ ...prev, leagueNameError: "League name is required" }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, leagueNameError: "" }));
    }

    if (form.draftDate === "") {
      setErrors(prev => ({ ...prev, draftDateError: "Draft date is required" }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, draftDateError: "" }));
    }

    if (form.pickTime === "") {
      setErrors(prev => ({ ...prev, pickTimeError: "Pick time is required" }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, pickTimeError: "" }));
    }

    if (form.rosterSize === "") {
      setErrors(prev => ({ ...prev, rosterSizeError: "Roster size is required" }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, rosterSizeError: "" }));
    }

    if (form.leagueSize === "") {
      setErrors(prev => ({ ...prev, leagueSizeError: "League size is required" }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, leagueSizeError: "" }));
    }

    if (form.killScore === "" || form.assistScore === "" || form.deathScore === "" || form.headshotScore === "") {
      setErrors(prev => ({ ...prev, scoreError: "All score settings are required" }));
      valid = false;
    } else {
      setErrors(prev => ({ ...prev, scoreError: "" }));
    }

    return valid;
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm(prev => ({ ...prev, [id]: value }));
  };

  return (
    //The images for the game that can be selected
    <div className="min-h-screen bg-black flex flex-col items-center">
      <div className='flex items-center justify-center gap-2 font-bold text-3xl pt-20 text-bright-yellow'>
        <img src={TrophyLogo} alt="" className='w-[48px] h-[48px]' />
        <h1>
          Welcome! Create your new league below!
        </h1>
      </div>

      <div className='bg-purple p-6 rounded-lg shadow-lg w-1/2 max-w-lg mt-8'>
        <div className='text-center text-red-500'>
          {errors.leagueNameError != "" && <p>{errors.leagueNameError}</p>}
          {errors.draftDateError != "" && <p>{errors.draftDateError}</p>}
          {errors.pickTimeError != "" && <p>{errors.pickTimeError}</p>}
          {errors.rosterSizeError != "" && <p>{errors.rosterSizeError}</p>}
          {errors.leagueSizeError != "" && <p>{errors.leagueSizeError}</p>}
        </div>

        <form onSubmit={(e) => handleSubmit(e)} className="space-y-4">
          <p className='text-xl text-bright-yellow'>General</p>

          <div>
            <label htmlFor='leagueName' className='text-bright-yellow'>Enter the name of your league</label>
            <input
              id='leagueName'
              placeholder='League name'
              onChange={handleChange}
              value={form.leagueName}
              className='mt-1 block w-full bg-light-blue px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
          </div>

          <div className='grid grid-cols-1'>
            <label htmlFor='draftDate' className='text-bright-yellow'>Select a time and date for your draft</label>
            <input
              id='draftDate'
              onChange={handleChange}
              value={form.draftDate}
              type='datetime-local'
              className='mt-1 block w-full bg-light-blue px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
          </div>

          <div className='grid grid-cols-1'>
            <label htmlFor='pickTime' className='text-bright-yellow'>How many seconds between draft picks?</label>
            <input
              id='pickTime'
              onChange={handleChange}
              placeholder='60'
              value={form.pickTime}
              type='number'
              max={300}
              min={10}
              className='mt-1 block w-full bg-light-blue px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
          </div>

          <div className='grid grid-cols-1'>
            <label htmlFor='rosterSize' className='text-bright-yellow'>How many players on a team?</label>
            <input
              id='rosterSize'
              onChange={handleChange}
              placeholder='5'
              value={form.rosterSize}
              type='number'
              max={10}
              min={1}
              className='mt-1 block w-full bg-light-blue px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
          </div>

          <div className='grid grid-cols-1'>
            <label htmlFor='leagueSize' className='text-bright-yellow'>How many teams in the league?</label>
            <input
              id='leagueSize'
              onChange={handleChange}
              placeholder='6'
              value={form.leagueSize}
              type='number'
              max={10}
              min={2}
              className='mt-1 block w-full bg-light-blue px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
          </div>

          <p className='text-xl text-bright-yellow'>Scoring</p>

          <div className='text-center text-red-500'>
            {errors.scoreError != "" && <p>{errors.scoreError}</p>}
          </div>

          <div className='grid grid-cols-1'>
            <label htmlFor='tournamentName' className='text-bright-yellow'>Which tournament do you want to play from?</label>
            <select
              id='tournamentName'
              value={form.tournamentName}
              onChange={handleChange}
              className='mt-1 block w-full bg-light-blue px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
              <option value="" disabled>Select an event</option>
              {tournamentNames.map((event, index) => (<option key={index} value={event}> {event} </option>))}
            </select>
          </div>

          <div className='grid grid-cols-1'>
            <label htmlFor='killScore' className='text-bright-yellow'>How many points per player kill?</label>
            <input
              id='killScore'
              onChange={handleChange}
              placeholder='250'
              value={form.killScore}
              type='number'
              max={1000}
              min={-1000}
              className='mt-1 block w-full bg-light-blue px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
          </div>

          <div className='grid grid-cols-1'>
            <label htmlFor='deathScore' className='text-bright-yellow'>How many points per player death?</label>
            <input
              id='deathScore'
              onChange={handleChange}
              placeholder='-100'
              value={form.deathScore}
              type='number'
              max={1000}
              min={-1000}
              className='mt-1 block w-full bg-light-blue px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
          </div>

          <div className='grid grid-cols-1'>
            <label htmlFor='assistScore' className='text-bright-yellow'>How many points per player assist?</label>
            <input
              id='assistScore'
              onChange={handleChange}
              placeholder='100'
              value={form.assistScore}
              type='number'
              max={1000}
              min={-1000}
              className='mt-1 block w-full bg-light-blue px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
          </div>

          <div className='grid grid-cols-1'>
            <label htmlFor='headshotScore' className='text-bright-yellow'>How many points per player headshot?</label>
            <input
              id='headshotScore'
              onChange={handleChange}
              placeholder='500'
              value={form.headshotScore}
              type='number'
              max={1000}
              min={-1000}
              className='mt-1 block w-full bg-light-blue px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
          </div>

          <div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-bright-yellow text-white font-semibold rounded-md shadow-sm hover:bg-brighter-yellow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}