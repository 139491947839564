import React from 'react';

function DeleteLeaguePopup({ onClose, onDelete, loading, error }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-60">
      <div className="bg-black bg-opacity-80 border-solid border-2 border-purple rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-2xl font-bold text-red-600">Delete League</h2>
        <p className="mt-4">Are you sure you want to delete this league? This action cannot be undone.</p>
        {loading ? 
            <div>
                Loading...
            </div>
             : 
            <div className="mt-6 flex  gap-4">
            {error && <p className="text-red-600">{error}</p>}
            <button
                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                onClick={onClose}
            >
                Cancel
            </button>
            <button
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                onClick={onDelete}
            >
                Delete
            </button>
            </div>
        }
      </div>
    </div>
  );
}

export default DeleteLeaguePopup;
