import React, { useState, useEffect } from 'react';
import Nav from '../components/Nav';
import { useAuth } from '../contexts/AuthProvider'
import TeamDetails from '../components/TeamDetails';
import TeamPageNavBar from '../components/teamPageNavbar';
import { useLeague } from '../contexts/LeagueProvider';
import smileyThumbsUp from '../images/goblin_8352319.png';
import car from '../images/people_12976185.png';
import dog from '../images/pancake_590710.png';
import pineapple from '../images/space_4718372.png';
import bread from '../images/breads_837618.png';



const Team = () => {
  const { activeLeagueId, setActiveLeagueId, userLeagues } = useLeague();
  const [isCommissioner, setIsCommissioner] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useAuth();
  // State to manage which form is open
  const [openForm, setOpenForm] = useState(null);
  
  
  // Form states
  const [playerId, setPlayerId] = useState('');
  const [team, setTeamId] = useState('');
  const [teamLogo, setTeamLogo] = useState('');
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [newTeamName, setNewTeamName] = useState('');

  



  const fetchTeamId = async (userEmail, usersLeagueId) => {
    console.log("in fetch");
    try {
      const response = await fetch(`/api/manage/get-Team`, {
        method: 'POST',
        headers: 
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          
        },
        body: JSON.stringify({
          email: userEmail, 
          leagueId: usersLeagueId, 
        }),
      });
      console.log("TeamID IN TEAM PAGE:  " , response)
      if (!response.ok)
         {
        throw new Error("Network response was not ok");
      }
  
      console.log("Team Id in Team Page:",response);
      const data = await response.json();
      console.log("Logo data",data);
      return data.teamId; 
    } catch (error) 
    {
      console.error("Error fetching team ID:", error);
      throw error;
    }
    
  };
  

  const getLogo = async (team) => {
    console.log("In get logo: ", team);
    try {
      const response = await fetch(`/api/manage/get-logo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          teamId: team,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data; 
    } catch (error) {
      console.error("Error fetching team logo:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchLogo = async () => {
      if (team) { // Ensure team is defined
        try {
          const logo = await getLogo(team);
          console.log(logo);
          setTeamLogo(logo); // Set the logo URL in state
          setLoading(false); // Set loading to false after fetching
        } catch (error) {
          console.error("Failed to fetch logo:", error);
          setLoading(false); // Set loading to false on error
        }
      }
    };

    fetchLogo();
  }, [team]);
  
  // Function to handle league selection from Nav
  const handleLeagueSelect = async (leagueId) => {
    setActiveLeagueId(leagueId);  // Update the activeLeagueId when Nav triggers a change
  
    const league = userLeagues.find((league) => league.leagueId === leagueId);
    setSelectedLeague(league);
  
    if (league) {
      const userEmail = sessionStorage.getItem('email'); // or fetch from your auth context if available
      setIsCommissioner(league.commissioner === userEmail);
  
      

      try {
        
        const fetchedTeamId = await fetchTeamId(userEmail, leagueId);
        
        console.log("Fetched Id"+fetchedTeamId);
        if (fetchedTeamId) {
          setTeamId(fetchedTeamId);
          console.log("Team in: " , team);
          
          console.log("Fetched team ID:", fetchedTeamId);
        } else {
          console.log("Fetched team ID is undefined.");
        }
      } catch (error) {
        console.error("Error fetching team ID:", error);
      }
      
    }

    
    
  };

  
  


      
    
    console.log("In team: ", team);

    
    //const teamId = db.collection('fantasy-teams')
    //.where('userEmail', '==', 'email')
    //.where('league', '==', 'league');
    //console.log("This is the associated team data", teamId);
  
  // Function to check if the draft date has passed
  const isDraftDatePassed = (draftDate) => {
    const currentDateTime = new Date();
    const draftDateTime = new Date(draftDate);
    return currentDateTime >= draftDateTime;
  };

  const handleButtonClick = (formName) => {
    setOpenForm(formName); // Set the form to open
  };

  // Function to close the form
  const closeForm = () => {
    setOpenForm(null); 
    setPlayerId('');
    setTeamLogo('');
    setNewTeamName('');
  };

  // Function to handle adding a player (example logic)
  const handleAddPlayer = async (e) => {
    e.preventDefault();
    const userEmail = sessionStorage.getItem('email');
    try {
        // Assuming playerId, leagueId, teamId, and email are available in scope
        console.log('Adding player:', playerId);
        console.log("email: ", userEmail , "PlayerId: " , playerId," leagueId: ", selectedLeague.leagueId, " Team Id: ", team);

        // Make API call to add the player
        const response = await fetch('/api/manage/add-player', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify({
                playerId: playerId,
                leagueId: selectedLeague.leagueId, 
                teamId: team,
                email: userEmail,
            }),
        });

        // Check for successful response
        const result = await response.json();
        if (result.success) {
            console.log('Player added successfully:', result.message);
            // Optionally show a success message to the user
        } else {
            console.error('Error adding player:', result.message);
            // Optionally show an error message to the user
        }
    } catch (error) {
        console.error('Unexpected error:', error);
        // Optionally show a generic error message to the user
    } finally {
        closeForm(); // Close the form after submission
    }
};


const handleDropPlayer = async (e) => {
  e.preventDefault();
  const userEmail = sessionStorage.getItem('email');
  try {
      // Assuming playerId, leagueId, teamId, and email are available in scope
      console.log('Adding player:', playerId);
      console.log("email: ", userEmail , "PlayerId: " , playerId," leagueId: ", selectedLeague.leagueId, " Team Id: ", team);

      // Make API call to add the player
      const response = await fetch('/api/manage/drop-player', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({
              playerId: playerId,
              leagueId: selectedLeague.leagueId, 
              teamId: team,
              email: userEmail,
          }),
      });

      // Check for successful response
      const result = await response.json();
      if (result.success) {
          console.log('Player added successfully:', result.message);
          // Optionally show a success message to the user
      } else {
          console.error('Error adding player:', result.message);
          // Optionally show an error message to the user
      }
  } catch (error) {
      console.error('Unexpected error:', error);
      // Optionally show a generic error message to the user
  } finally {
      closeForm(); // Close the form after submission
  }
};
 
const handleChangeLogo = async (e) => {
  e.preventDefault();

  console.log('Changing logo for:', {
    leagueId: selectedLeague.leagueId, 
    teamId: team,
    newLogoUrl: teamLogo,
  });

  try {
      const response = await fetch('/api/manage/change-logo', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({
              leagueId: selectedLeague.leagueId, 
              teamId: team,
              newLogoUrl: teamLogo, // Assuming teamLogo is the new logo URL
          }),
      });

      const data = await response.json(); // Await the response to get the data
      console.log('Response from server:', data);

      if (data.success) {
          console.log('Logo changed successfully!');
          // You may want to update the UI or state here to reflect the new logo
      } else {
          console.error('Error changing logo:', data.message);
      }
  } catch (error) {
      console.error('Error during logo change:', error);
  }

  closeForm(); // Close the form after submission
};
 
  const handleChangeTeamName =async (e) => {
    e.preventDefault();
    const userEmail = sessionStorage.getItem('email');
    console.log("email: ", userEmail , "New Team Name: " , newTeamName ," leagueId: ",team);
    try {
      const response = await fetch(`/api/manage/change-teamName`, {
        method: 'POST',
        headers: 
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          
        },
        body: JSON.stringify({
          newName: newTeamName ,
          leagueId: selectedLeague, 
          teamId: team,
        }),
      });
      //console.log("TeamID IN TEAM PAGE:  " , response)
      if (!response.ok)
         {
        throw new Error("Network response was not ok");
      }
  
      //console.log("Team Id in Team Page:",response);
      const data = await response.json();
      //return data.teamId; 
    } catch (error) 
    {
      console.error("Error fetching team ID:", error);
      throw error;
    }
   
    console.log('Changing team name to:', newTeamName);
    closeForm(); 
  };

  return (
    <div className="grid bg-black text-bright-yellow grid-cols-6 gap-4 h-screen">
      {/* Nav component */}
      <div className='bg-purple'>
        <Nav className="col-span-1 p-4" onLeagueSelect={handleLeagueSelect} />
      </div>

      {/* Main Content */}
      <div className="col-span-5 flex flex-col h-full">
        {/* Nav Bar */}
        <div>
          <TeamPageNavBar className="bg-black p-4 flex justify-between items-center" leagueId={activeLeagueId} />
        </div>

        {/* Horizontal Divider */}
        <div className="border-t-2 border-black my-1" />

        {selectedLeague && isDraftDatePassed(selectedLeague.draftDate) ? (
          <>
            {/* Grid Section */}
            <div className="bg-purple p-4 rounded shadow-lg">
              <div className="grid grid-cols-4 gap-4 auto-rows-auto">
                {/* Row 1 */}
                <button onClick={() => handleButtonClick('addPlayer')}>
                  <div className="rounded bg-bright-yellow hover:bg-brighter-yellow text-purple p-2 flex items-center justify-center">
                    Add Player
                  </div>
                </button>

                <button className="row-span-2 self-stretch">
            <div className="rounded bg-bright-yellow h-full  text-purple p-2 flex items-center justify-center">
                {loading ? (
            <span>Loading...</span> // Optionally, show a loading state
              ) : (
             <img src ="/static/media/people_12976185.3825cb9abd6d86a3d699.png"  alt="Team Logo" className="h-16 w-16 sm:h-24 sm:w-24 md:h-32 md:w-32 object-contain" />
            )}
           </div>
              </button>

                <button onClick={() => handleButtonClick('tradePlayer')}>
                  <div className="rounded bg-bright-yellow hover:bg-brighter-yellow text-purple p-2 flex items-center justify-center">
                    Trade Player
                  </div>
                </button>

                {/* Row 2 */}
                <button onClick={() => handleButtonClick('changeLogo')}>
                  <div className="rounded bg-bright-yellow hover:bg-brighter-yellow text-purple p-2 flex items-center justify-center">
                    Change Team Logo
                  </div>
                </button>

                <button onClick={() => handleButtonClick('dropPlayer')}>
                  <div className="rounded bg-bright-yellow hover:bg-brighter-yellow text-purple p-2 flex items-center justify-center">
                    Drop Player
                  </div>
                </button>

                <button onClick={() => handleButtonClick('changeTeamName')}>
                  <div className="rounded bg-bright-yellow hover:bg-brighter-yellow text-purple p-2 flex items-center justify-center">
                    Team Name
                  </div>
                </button>

                <button>
                  <div className="rounded bg-bright-yellow hover:bg-brighter-yellow text-purple p-2 flex items-center justify-center">
                    Save
                  </div>
                </button>
              </div>
            </div>

            <div className="border-t-2 border-black my-2" />

            <TeamDetails className="p-4 bg-black" />
          </>
        ) : (
          selectedLeague && (
            <p className="text-3xl p-4 text-mono text-center">
              {selectedLeague.leagueName}'s draft day is on {new Date(selectedLeague.draftDate).toLocaleDateString()} at {new Date(selectedLeague.draftDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </p>
          )
        )}
      </div>

      {/* Form Popup Logic */}
      {openForm === 'addPlayer' && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <form onSubmit={handleAddPlayer} className="bg-purple p-4 border-3 border-bright-yellow rounded shadow-lg border-2">
            <h2 className="text-lg font-bold">Add Player</h2>
            <input
              type="text"
              placeholder="Player ID"
              value={playerId}
              onChange={(e) => setPlayerId(e.target.value)}
              required
              className="border p-2 w-full"
            />
            <button type="submit" className="bg-bright-yellow text-white p-2 rounded mx-2 mt-2">Add</button>
            <button type="button" onClick={closeForm} className="bg-bright-yellow text-white p-2 rounded mt-2">Cancel</button>
          </form>
        </div>
      )}


{openForm === 'dropPlayer' && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <form onSubmit={handleDropPlayer} className="bg-purple p-4 border-3 border-bright-yellow rounded shadow-lg border-2">
            <h2 className="text-lg font-bold">Drop Player</h2>
            <input
              type="text"
              placeholder="Player ID"
              value={playerId}
              onChange={(e) => setPlayerId(e.target.value)}
              required
              className="border p-2 w-full"
            />
            <button type="submit" className="bg-bright-yellow text-white p-2 rounded mt-2 mx-2">Add</button>
            <button type="button" onClick={closeForm} className="bg-bright-yellow text-white p-2 rounded mt-2">Cancel</button>
          </form>
        </div>
      )}

{openForm === 'changeLogo' && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <form onSubmit={handleChangeLogo} className="bg-purple border-3 border-bright-yellow p-4 rounded shadow-lg border-2">
      <h2 className="text-lg font-bold">Team Logo</h2>
      <div className="grid grid-cols-5 gap-4 mb-4">
        {[smileyThumbsUp, car, dog, pineapple, bread].map((logo, index) => (
          <div 
            key={index} 
            className={`cursor-pointer ${teamLogo === logo ? 'border-2 border-bright-yellow' : 'border-2 border-transparent'}`} 
            onClick={() => setTeamLogo(logo)} // Set the logo when clicked
          >
            <img src={logo} alt={`Logo ${index + 1}`} className="w-full h-24 object-contain" />
          </div>
        ))}
      </div>
      <button type="submit" className="bg-bright-yellow text-white p-2 rounded mx-2 mt-2">Change Logo</button>
      <button type="button" onClick={closeForm} className="bg-bright-yellow text-white p-2 rounded mt-2">Cancel</button>
    </form>
  </div>
)}

      
      
     


      {openForm === 'changeTeamName' && (
        <div className="fixed inset-0 flex items-center justify-center   bg-purple bg-opacity-50">
          <form onSubmit={handleChangeTeamName} className="bg-purple border-2 border-bright-yellow p-4 rounded shadow-lg">
            <h2 className="text-lg font-bold">Change Team Name</h2>
            <input
              type="text"
              placeholder="New Team Name"
              value={newTeamName}
              onChange={(e) => setNewTeamName(e.target.value)}
              required
              className="border p-2 w-full border-bright-yellow"
            />
            <button type="submit" className="bg-bright-yellow text-purple p-2 rounded mx-2 mt-2">Change</button>
            <button type="button" onClick={closeForm} className="bg-bright-yellow text-purple p-2 rounded mt-2">Cancel</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Team;
