import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faX} from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../contexts/AuthProvider'
import { useNavigate, Link } from 'react-router-dom'

const SideNavMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { logoutUser, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    await logoutUser();
    navigate("/login")
  }

  return (
    <div className="relative">
      <button
        className="p-4 text-purple bg-bright-yellow hover:bg-brighter-yellow duration-200 ease-in py-1 border-2 border-purple rounded-md text-xl"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={faBars}/>
      </button>

      <div className={`fixed inset-y-0 left-0 w-64 bg-gray-800 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <button
          className="p-4 text-purple bg-bright-yellow flex flex-col justify-center hover:bg-brighter-yellow duration-200 ease-in py-1 border-2 border-purple rounded-md text-xl"
          onClick={() => setIsOpen(false)}
        >
          <FontAwesomeIcon icon={faX}/>
        </button>
        <div className="p-4 flex flex-col items-center text-xl text-white">
          <div className=''>
            <Link id="home" to="/">Home</Link>
          </div>
          <Link id="dashboard" to="/dashboard">Dashboard</Link>
          <Link id="profile" to="/profile">Profile</Link>
          <div className="">
           <button onClick={logout} className='bg-bright-yellow hover:bg-brighter-yellow duration-200 ease-in text-purple px-12 py-1 border-2 border-purple rounded-md text-xl'>Logout</button>
         </div>
        </div>
      </div>
    </div>
  );
}

export default SideNavMenu;
