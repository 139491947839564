import React, { useEffect, useState } from 'react';
import { useLeague } from '../contexts/LeagueProvider';
import { useAuth } from '../contexts/AuthProvider';
import {useNavigate } from 'react-router-dom';
import DeleteLeaguePopup from '../components/popups/DeleteLeaguePopup';
import DeleteTeamPopup from '../components/popups/DeleteTeamPopup';
import InvitePlayerPopup from '../components/popups/InvitePlayerPopup';
import SideNavMenu from '../components/SideNavMenu';

function LeagueSettings() {
  const { token, user } = useAuth();
  const navigate = useNavigate();
  const { getLeagueSettings, leagueSettings, setLeagueSettings, deleteLeague, updateLeagueSettings } = useLeague();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editLeagueSettings, setEditLeagueSettings] = useState({
    leagueName: false,
    draftDate: false,
    pickTime: false,
    leagueId: false,
    leagueSize: false,
    rosterSize: false,
    killScore: false,
    deathScore: false,
    assistScore: false,
    headshotScore: false,
  });
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("");

  useEffect(() => {
    getLeagueSettings();
  }, []);

  const handleDeleteLeague = async () => {
    setLoading(true);
    await deleteLeague();
    console.log("League deleted");
    setIsPopupOpen(false);
    setLoading(false);
    navigate("/dashboard");
  };

  const handleEditLeague = async (field) => {
    console.log("Editing league settings");
    setLoading(true);

    const res = await updateLeagueSettings(field);

    if(res.err) {
      setError("Could not update league setting. Try again")
      setLoading(false);
      return;
    }

    setLoading(false);

    setEditLeagueSettings((prevSettings) => ({
      ...prevSettings,      // Keep the existing settings
      [field]: false        // Update the specific field to false
    }));
  }

  return (
    <div className="min-h-screen bg-black text-bright-yellow flex items-center justify-center">
      <div className="min-h-screen p-4 md:p-8 flex flex-col gap-8 w-full max-w-3xl border-solid border-x-4 border-purple border-opacity-40">
        <SideNavMenu/>

        <div className="text-left">
          <h1 className="text-4xl font-bold">League Settings</h1>
          <span className="font-light text-sky-400 block mt-2">League ID: {leagueSettings.leagueId}</span>
        </div>

        {leagueSettings.commissioner === user ? (
          <div className='flex flex-col'>
            <h2 className='text-md font-bold p-1 bg-purple bg-opacity-70 border-solid border-2 border-purple w-fit'>Commissioner Actions</h2>
            <div className="flex flex-col md:flex-row justify-between gap-2 border-solid border-y-2 border-gray-600 py-4">
                {/*This is the invite player */}
                <InvitePlayerPopup/>
                {/*This is the Delete Team*/}
                <DeleteTeamPopup/>
              <button
                className="bg-bright-yellow text-purple border-solid border-bright-yellow w-full border-2 py-2 px-4 rounded-md hover:bg-transparent hover:text-bright-yellow duration-300 ease-in"
                onClick={() => setIsPopupOpen(true)}
              >
                Delete League
              </button>
            </div>
          </div>
        ) : null}

        <div className="bg-purple bg-opacity-50 border-solid border-4 border-purple rounded-lg">
          <table className="w-full text-left text-xl table-auto">
            <tbody>
              <tr className="border-b border-purple hover:bg-purple hover:bg-opacity-70 transition duration-200 ease-in-out">
                <th className="p-4">League Name</th>
                {editLeagueSettings.leagueName ? (
                  <div className='flex justify-end gap-4 p-4'>
                    <input onChange={(e)=> setLeagueSettings({...leagueSettings, leagueName: e.target.value})} type="text" className='bg-black bg-opacity-50 text-bright-yellow border-solid border-2 border-purple rounded-md '/>
                      <div className="flex gap-2 text-black">
                        {loading ? <p>Loading...</p> :
                         <>
                         <button onClick={() => setEditLeagueSettings({...editLeagueSettings, leagueName: false})} className='bg-red-400 p-2 px-4 rounded-lg text-sm'>Cancel</button>
                         <button onClick={() => handleEditLeague('leagueName')}className='bg-green-400 p-2 px-4 rounded-lg text-sm'>Save</button>
                        </>
                        }
                      </div>
                   
                  </div>
                ) : (
                  <div className='flex justify-end items-center pr-4'>
                    <span className="p-4 text-md md:text-xl">{leagueSettings.leagueName}</span>
                    <button onClick={() => setEditLeagueSettings({...editLeagueSettings, leagueName: true})}className=' rounded-lg text-sm h-fit bg-black bg-opacity-50 p-2 px-4'>Edit</button>
                  </div>
                  
                )}
                </tr>
                <tr className="border-b border-purple hover:bg-purple hover:bg-opacity-70 transition duration-200 ease-in-out">
                <th className="p-4">Draft Date</th>
                {editLeagueSettings.draftDate ? (
                  <div className='flex justify-end gap-4 p-4'>
                    <input onChange={(e)=> setLeagueSettings({...leagueSettings, draftDate: e.target.value})} type="datetime-local" className='bg-black bg-opacity-50 text-bright-yellow border-solid border-2 border-purple rounded-md'/>
                      <div className="flex gap-2 text-black">
                        {loading ? <p>Loading...</p> :
                          <>
                          <button onClick={() => setEditLeagueSettings({...editLeagueSettings, draftDate: false})}className='bg-red-400 p-2 px-4 rounded-lg text-sm'>Cancel</button>
                          <button onClick={() => handleEditLeague('draftDate')}className='bg-green-400 p-2 px-4 rounded-lg text-sm'>Save</button>
                          </>
                          }
                      </div>
                   
                  </div>
                ) : (
                  <div className='flex justify-end items-center pr-4'>
                    <span className="p-4 text-md md:text-xl">{leagueSettings.draftDate}</span>
                    <button onClick={() => setEditLeagueSettings({...editLeagueSettings, draftDate: true})} className=' rounded-lg text-sm h-fit bg-black bg-opacity-50 p-2 px-4'>Edit</button>
                  </div>
                  
                )}
                </tr>
                <tr className="border-b border-purple hover:bg-purple hover:bg-opacity-70 transition duration-200 ease-in-out">
                <th className="p-4">Pick Time (seconds)</th>
                {editLeagueSettings.pickTime ? (
                  <div className='flex justify-end gap-2 p-4 text-black'>
                    <input onChange={(e)=> setLeagueSettings({...leagueSettings, pickTime: e.target.value})} type="number" min={10} max={300} className='bg-black bg-opacity-50 text-bright-yellow border-solid border-2 border-purple rounded-md'/>
                      {loading ? <p>Loading...</p> :
                        <>
                          <button onClick={() => setEditLeagueSettings({...editLeagueSettings, pickTime: false})}className='bg-red-400 p-2 px-4 rounded-lg text-sm'>Cancel</button>
                          <button onClick={() => handleEditLeague('pickTime')}className='bg-green-400 p-2 px-4 rounded-lg text-sm'>Save</button>
                        </>
                        }
                   
                  </div>
                ) : (
                  <div className='flex justify-end items-center pr-4'>
                    <span className="p-4 text-md md:text-xl">{leagueSettings.pickTime}</span>
                    <button onClick={() => setEditLeagueSettings({...editLeagueSettings, pickTime: true})} className=' rounded-lg text-sm h-fit bg-black bg-opacity-50 p-2 px-4'>Edit</button>
                  </div>
                  
                )}
                </tr>
                
                <tr className="border-b border-purple hover:bg-purple hover:bg-opacity-70 transition duration-200 ease-in-out">
                <th className="p-4">League Size</th>
                {editLeagueSettings.leagueSize ? (
                  <div className='flex justify-end gap-2 p-4 text-black'>
                    <input onChange={(e)=> setLeagueSettings({...leagueSettings, leagueSize: e.target.value})} type="number" min={2} max={10} className='bg-black bg-opacity-50 text-bright-yellow border-solid border-2 border-purple rounded-md'/>
                      {loading ? <p>Loading...</p> :
                        <>
                          <button onClick={() => setEditLeagueSettings({...editLeagueSettings, leagueSize: false})}className='bg-red-400 p-2 px-4 rounded-lg text-sm'>Cancel</button>
                          <button onClick={() => handleEditLeague('leagueSize')}className='bg-green-400 p-2 px-4 rounded-lg text-sm'>Save</button>
                        </>
                      }
                  </div>
                ) : (
                  <div className='flex justify-end items-center pr-4'>
                    <span className="p-4 text-md md:text-xl">{leagueSettings.leagueSize}</span>
                    <button onClick={() => setEditLeagueSettings({...editLeagueSettings, leagueSize: true})} className=' rounded-lg text-sm h-fit bg-black bg-opacity-50 p-2 px-4'>Edit</button>
                  </div>
                  
                )}
                </tr>
                <tr className="border-b border-purple hover:bg-purple hover:bg-opacity-70 transition duration-200 ease-in-out">
                <th className="p-4">Roster Size</th>
                {editLeagueSettings.rosterSize ? (
                  <div className='flex justify-end gap-2 p-4 text-black'>
                    <input onChange={(e)=> setLeagueSettings({...leagueSettings, rosterSize: e.target.value})} type="number" min={1} max={10} className='bg-black bg-opacity-50 text-bright-yellow border-solid border-2 border-purple rounded-md'/>
                      {loading ? <p>Loading...</p> :
                        <>
                          <button onClick={() => setEditLeagueSettings({...editLeagueSettings, rosterSize: false})}className='bg-red-400 p-2 px-4 rounded-lg text-sm'>Cancel</button>
                          <button onClick={() => handleEditLeague('rosterSize')}className='bg-green-400 p-2 px-4 rounded-lg text-sm'>Save</button>
                        </>
                        }
                   
                  </div>
                ) : (
                  <div className='flex justify-end items-center pr-4'>
                    <span className="p-4 text-md md:text-xl">{leagueSettings.rosterSize}</span>
                    <button onClick={() => setEditLeagueSettings({...editLeagueSettings, rosterSize: true})} className=' rounded-lg text-sm h-fit bg-black bg-opacity-50 p-2 px-4'>Edit</button>
                  </div>
                  
                )}
                </tr>
                <tr className="border-b border-purple hover:bg-purple hover:bg-opacity-70 transition duration-200 ease-in-out">
                <th className="p-4">Kill Score</th>
                {editLeagueSettings.killScore ? (
                  <div className='flex justify-end gap-2 p-4 text-black'>
                    <input onChange={(e)=> setLeagueSettings({...leagueSettings, killScore: e.target.value})} type="number" min={1} max={10} className='bg-black bg-opacity-50 text-bright-yellow border-solid border-2 border-purple rounded-md'/>
                      {loading ? <p>Loading...</p> :
                        <>
                          <button onClick={() => setEditLeagueSettings({...editLeagueSettings, killScore: false})}className='bg-red-400 p-2 px-4 rounded-lg text-sm'>Cancel</button>
                          <button onClick={() => handleEditLeague('killScore')}className='bg-green-400 p-2 px-4 rounded-lg text-sm'>Save</button>
                        </>
                        }
                   
                  </div>
                ) : (
                  <div className='flex justify-end items-center pr-4'>
                    <span className="p-4 text-md md:text-xl">{leagueSettings.killScore}</span>
                    <button onClick={() => setEditLeagueSettings({...editLeagueSettings, killScore: true})} className=' rounded-lg text-sm h-fit bg-black bg-opacity-50 p-2 px-4'>Edit</button>
                  </div>
                  
                )}
                </tr>
                <tr className="border-b border-purple hover:bg-purple hover:bg-opacity-70 transition duration-200 ease-in-out">
                <th className="p-4">Death Score</th>
                {editLeagueSettings.deathScore ? (
                  <div className='flex justify-end gap-2 p-4 text-black'>
                    <input onChange={(e)=> setLeagueSettings({...leagueSettings, deathScore: e.target.value})} type="number" min={1} max={10} className='bg-black bg-opacity-50 text-bright-yellow border-solid border-2 border-purple rounded-md'/>
                      {loading ? <p>Loading...</p> :
                        <>
                          <button onClick={() => setEditLeagueSettings({...editLeagueSettings, deathScore: false})}className='bg-red-400 p-2 px-4 rounded-lg text-sm'>Cancel</button>
                          <button onClick={() => handleEditLeague('deathScore')}className='bg-green-400 p-2 px-4 rounded-lg text-sm'>Save</button>
                        </>
                        }
                   
                  </div>
                ) : (
                  <div className='flex justify-end items-center pr-4'>
                    <span className="p-4 text-md md:text-xl">{leagueSettings.deathScore}</span>
                    <button onClick={() => setEditLeagueSettings({...editLeagueSettings, deathScore: true})} className=' rounded-lg text-sm h-fit bg-black bg-opacity-50 p-2 px-4'>Edit</button>
                  </div>
                  
                )}
                </tr>
                <tr className="border-b border-purple hover:bg-purple hover:bg-opacity-70 transition duration-200 ease-in-out">
                <th className="p-4">Assist Score</th>
                {editLeagueSettings.assistScore ? (
                  <div className='flex justify-end gap-2 p-4 text-black'>
                    <input onChange={(e)=> setLeagueSettings({...leagueSettings, assistScore: e.target.value})} type="number" min={1} max={10} className='bg-black bg-opacity-50 text-bright-yellow border-solid border-2 border-purple rounded-md'/>
                      {loading ? <p>Loading...</p> :
                        <>
                          <button onClick={() => setEditLeagueSettings({...editLeagueSettings, assistScore: false})}className='bg-red-400 p-2 px-4 rounded-lg text-sm'>Cancel</button>
                          <button onClick={() => handleEditLeague('assistScore')}className='bg-green-400 p-2 px-4 rounded-lg text-sm'>Save</button>
                        </>
                        }
                   
                  </div>
                ) : (
                  <div className='flex justify-end items-center pr-4'>
                    <span className="p-4 text-md md:text-xl">{leagueSettings.assistScore}</span>
                    <button onClick={() => setEditLeagueSettings({...editLeagueSettings, assistScore: true})} className=' rounded-lg text-sm h-fit bg-black bg-opacity-50 p-2 px-4'>Edit</button>
                  </div>
                  
                )}
                </tr>
                <tr className="border-b border-purple hover:bg-purple hover:bg-opacity-70 transition duration-200 ease-in-out">
                <th className="p-4">Headshot Score</th>
                {editLeagueSettings.headshotScore ? (
                  <div className='flex justify-end gap-2 p-4 text-black'>
                    <input onChange={(e)=> setLeagueSettings({...leagueSettings, headshotScore: e.target.value})} type="number" min={1} max={10} className='bg-black bg-opacity-50 text-bright-yellow border-solid border-2 border-purple rounded-md'/>
                      {loading ? <p>Loading...</p> :
                        <>
                          <button onClick={() => setEditLeagueSettings({...editLeagueSettings, headshotScore: false})}className='bg-red-400 p-2 px-4 rounded-lg text-sm'>Cancel</button>
                          <button onClick={() => handleEditLeague('headshotScore')}className='bg-green-400 p-2 px-4 rounded-lg text-sm'>Save</button>
                        </>
                        }
                  </div>
                ) : (
                  <div className='flex justify-end items-center pr-4'>
                    <span className="p-4 text-md md:text-xl">{leagueSettings.headshotScore}</span>
                    <button onClick={() => setEditLeagueSettings({...editLeagueSettings, headshotScore: true})} className=' rounded-lg text-sm h-fit bg-black bg-opacity-50 p-2 px-4'>Edit</button>
                  </div>
                  
                )}
                </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Conditionally render the popup */}
      {isPopupOpen && (
        <DeleteLeaguePopup
          onClose={() => setIsPopupOpen(false)}
          onDelete={handleDeleteLeague}
          loading={loading}
          error={error}
        />
      )}

    </div>
  );
}

export default LeagueSettings;
