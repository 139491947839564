import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Make sure to import useNavigate
import Nav from '../components/Nav';
import CommissionerPageNavBar from '../components/CommissionerPageNavbar';
import UserPageNavBar from '../components/UserPageNavBar';
import TeamDetails from '../components/TeamDetails';
import Standings from '../components/Standings';
import { useLeague } from '../contexts/LeagueProvider';

const Dashboard = () => {
  const { activeLeagueId, setActiveLeagueId, userLeagues } = useLeague();
  const [isCommissioner, setIsCommissioner] = useState(false);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const navigate = useNavigate(); // Initialize navigate for navigation

  // Function to handle league selection from Nav
  const handleLeagueSelect = (leagueId) => {
    console.log("League ID ", leagueId);
    setActiveLeagueId(leagueId);  // Update the activeLeagueId when Nav triggers a change

    // Find the selected league
    const league = userLeagues.find((league) => league.leagueId === leagueId);
    setSelectedLeague(league);

    // Check if the user is the commissioner of the selected league
    if (league) {
      const userEmail = sessionStorage.getItem('email'); // or fetch from your auth context if available
      setIsCommissioner(league.commissioner === userEmail);
    }
  };

  // Function to check if the draft date has passed
  const isDraftDatePassed = (draftDate) => {
    const currentDateTime = new Date();
    const draftDateTime = new Date(draftDate);
    return currentDateTime >= draftDateTime;
  };

  // Function to check if it's within 15 minutes of the draft date
  const shouldDisplayButton = (draftDate) => {
    const currentDateTime = new Date();
    const draftDateTime = new Date(draftDate);

    const timeDifference = draftDateTime - currentDateTime;
    const fifteenMinutesInMillis = 15 * 60 * 1000;
    return timeDifference <= fifteenMinutesInMillis && timeDifference > 0;
  };

  return (
    <div className="grid bg-black text-bright-yellow grid-cols-6 gap-4 h-screen">
      {/* Nav component */}
      <div className='bg-purple'>
        <Nav className="col-span-1 p-4" onLeagueSelect={handleLeagueSelect} />
      </div>
      {/* Main Content */}
      <div className="col-span-4">
        {isCommissioner ? (
          <CommissionerPageNavBar className="bg-black p-4 flex justify-between items-center" leagueId={activeLeagueId} />
        ) : (
          <UserPageNavBar className="bg-black p-4 flex justify-between items-center" leagueId={activeLeagueId} />
        )}
        <div className="my-4"></div> {/* Vertical margin between navbar and content */}
        {selectedLeague && isDraftDatePassed(selectedLeague.draftDate) ? (
          <TeamDetails className="p-4 bg-black" />
        ) : (
          selectedLeague && (
            <div className="text-center">
              <p className="text-3xl p-4 text-mono">
                {selectedLeague.leagueName}'s draft day is on {new Date(selectedLeague.draftDate).toLocaleDateString()} at {new Date(selectedLeague.draftDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </p>

              {/* Add the logic to display the button within 15 minutes of the draft */}
              {shouldDisplayButton(selectedLeague.draftDate) && (
                <button
                  onClick={() => navigate('/draft-room/${activeLeagueId}')} // assuming you are using a navigation function like `navigate`
                  className="mt-4 p-2 bg-bright-yellow text-purple text-monobold rounded onHover: hover:bg-brighter-yellow"
                >
                  Join Draft
                </button>
              )}
            </div>
          )
        )}
      </div>
      {/* Standings Component */}
      <div className="col-span-1 bg-purple p-4">
        {activeLeagueId ? (
          <Standings leagueId={activeLeagueId} />
        ) : (
          <p>Please select a league from the navigation to view standings.</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
