import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';

const ForgotPassword = () => {
  const { resetPassword, doesUserExist, loading } = useAuth();
  const [form, setForm] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    emailError: "",
  });
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('auth') || false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     console.log("User is logged in, navigating from login to dashboard")
  //     navigate('/dashboard')
  //   } else {
  //     console.log("User is not logged in")
  //   }
  // }, [isAuthenticated]);


  const validateForm = async () => {
    let valid = true;
    console.log("Validating form")
    console.log("Form: ", form)
    if (form.email === "") {
      setErrors(prev => ({ ...prev, emailError: "Email is required" }));
      valid = false;
    } else {
      const doesExist = await doesUserExist(form.email);
      if (!doesExist) {
        setErrors(prev => ({ ...prev, emailError: "Account not found" }));
        valid = false;
      } else {
        setErrors(prev => ({ ...prev, emailError: "" }));
      }
    }
    
    return valid;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm(prev => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted")
    if (await validateForm()) {
      try {
        await resetPassword(form);
        setIsSubmitted(true)
      } catch (err) {
        console.error("Forget Password Request Error:", err);
      }
    }
  };

  return (
    <div className="min-h-screen bg-black flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-w-sm text-center">
        <h1 className="text-2xl font-bold mb-4">Forgot your password?</h1>
        <p className="text-center mb-6">
          Enter the email associated with your account and we will send you a recovery email
        </p>
        {isSubmitted &&
          <p className="text-center mb-6">
            Recovery email sent!
          </p>
        }
        <div className='text-red-500 my-4'>
          {errors.emailError != "" && <p>{errors.emailError}</p>}
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              id="email"
              type="email"
              placeholder="Email"
              onChange={handleChange}
              value={form.email}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;